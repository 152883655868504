<template>
	<footer class="main-footer">
		<div class="container mx-auto">
			<div class="grid grid-cols-1 lg:grid-cols-2 px-8 py-8 gap-y-4">
				<article class="flex flex-col justify-between space-y-4">
					<img src="../assets/images/ccic-logo-white.svg" alt="CCIC Logo" width="150" height="auto" class="w-full md:max-w-150" />
					<p class="leading-4">
						<small>
							Consortium for the Investigation of Cannabinoids
							<br class="">
							PO Box 37095 Gordon Park, 6495 Victoria Dr., Vancouver, BC, V5P 4W7
						</small>
					</p>
					<div class="footer-contact">
						<small class="footer-contact-text">
							<a href="mailto:info@ccic.net">General inquiries:  info@ccic.net</a>
						</small>
						<small class="footer-contact-text">
							<a href="mailto:ccs@ccic.net">CCS inquiries: ccs@ccic.net</a>
						</small>
						<small class="footer-contact-text">
							<a href="https://twitter.com/ccictweeter?lang=en" target="_blank" rel="noopener">@CCICTwitter</a>
						</small>
					</div>
				</article>
				<article class="flex flex-col justify-between space-y-4">
					<div class="footer-terms">
						<a href="https://ccic.net/privacy-policy/" target="_blank">Privacy Policy</a>
						<a href="https://ccic.net/terms-of-use/" target="_blank">Terms and Conditions</a>
					</div>
					<div class="footer-copyright">
						<small>
							&copy; 2020 Canadian Consortium for the Investigation of Cannabinoids
						</small>
					</div>
				</article>
			</div>
		</div>
	</footer>
</template>
<script>
export default {
	name: 'MainFooter'
}
</script>
<style lang="postcss" scoped>
	.main-footer {
		background-color: theme('colors.light-gray');
		color: #fff;
		text-align: center;
	}
	.footer-contact-text {
		min-width: 200px;
		display: inline-flex;
		justify-content: center;
	}
	.footer-terms, .footer-copyright {
		text-align: center;
	}
	.footer-terms {
		> * {
			display: block;
			width: 100%;
			padding: 0.25rem 0;
		}
	}

	/* Custom responsive class */
	@responsive {
		.max-w-150 {
			max-width: 150px;
		}
	}

	@screen md {
		.main-footer {
			text-align: left;
		}
		.footer-contact-text:not(:last-of-type) {
			border-right: 2px solid #fff;
		}
		.footer-contact-text:not(:first-of-type) {
			justify-content: center;
		}
		.footer-terms, .footer-copyright {
			text-align: right;
		}
		.footer-terms {
			> * {
				display: inline-block;
				width: auto;
			}
			> *:not(:last-of-type) {
				padding: 0 2.5rem;
			}
		}
	}
</style>

<template>
	<div class="module-card" :class="cssClass">
		<section class="card-media-wrapper">
			<slot name="card-media"></slot>
		</section>
		<section class="card-content-wrapper">
			<slot name="card-content"></slot>
		</section>
	</div>
</template>
<script>
export default {
	name: 'ModuleMediaCard',
	props: {
		layoutStyle: {
			type: String
		}
	},
	components: {},
	computed: {
		cssClass() {
			return this.layoutStyle || 'default';
		}
	}
}
</script>
<style lang="postcss" scoped>
	/* General styles */
	.module-card {
		@apply w-full;
		min-height: 200px;
		display: flex;
		flex-direction: column;
		border: 2px solid var(--card-border-color, none);
	}

	.card-media-wrapper {
		/* Temporary */
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: fit-content;
		background-color: var(--media-background-color);
	}

	.card-media-wrapper > * {
		object-fit: cover;
		max-width: 100%;
	}

	.card-content-wrapper {
		@apply px-3 py-6;
		height: 100%;
		color: var(--content-text-color);
		background-color: var(--content-background-color);
		h3 {
			font-weight: bold;
		}
		> * {
			color: inherit;
		}
	}

	 /* Media Queries */
	@screen md {}

	@screen lg {}

	/* Default theme */
    .module-card.default {
        --content-text-color: theme('colors.cool-gray');
    }
	/* Default theme for cards containing media */
	.module-card.default--video {
		--content-text-color: #fff;
		--content-background-color: theme('colors.solid-green');
	}

	/* Bright theme */
	.module-card.bright {
		--card-border-color: theme('colors.solid-green');
		--content-text-color: #fff;
		--content-background-color: theme('colors.solid-green');
	}

	/* Dark theme */
	.module-card.dark {
		--card-border-color: theme('colors.dark-teal');
		--content-text-color: #fff;
		--content-background-color: theme('colors.dark-teal');
	}

	/* Dig Deeper theme */
	.module-card.dig-deeper {
        --content-text-color: #fff;
        --content-background-color: theme('colors.dark-teal');
    }

	/* Patient's Ask theme */
	.module-card.patients-ask {
        --content-text-color: #fff;
        --content-background-color: theme('colors.cool-gray');
    }
</style>
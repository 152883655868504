<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="ksharkey">
		<template #faculty-title>
			KEITH SHARKEY, PhD, CAGF, FCAHS
		</template>
		<template #faculty-details>
			<p>Professor of Physiology and Pharmacology, University of Calgary</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'KSHarkeyCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="side-nav-wrapper" :class="displaySideNav ? 'block' : 'hidden'" role="tree">
		<div class="icon-wrapper">
			<font-awesome-icon :icon="['fas', 'times']" class="fa-2x fill-current" title="Close" @click="closeNav"/>
		</div>
		<div class="side-nav-menu-wrapper">
			<div class="tree-nav-wrapper" v-for="(section, index) in content.sections" :key="index">
				<tree-menu :module="module" :treeItem="section" :treeNodes="section.children" :depth="0" />
			</div>
			<!-- <ul>
				<li>
				</li>
			</ul> -->
			<!-- <details v-for="section in content.sections" :key="section.id">
				<summary>
					<a class="cursor-pointer" v-if="section.hasPage === false">{{ section.name }}</a>
					<router-link v-else :to='{ name: "ModuleViewer", params: { module: module, section: section.id } }'>{{section.name}}</router-link>
					<span v-if="section.children != null">^</span>
					<details v-if="section.children != null">
						<ul v-for="child in section.children" :key="child.id">
							<li>
								<router-link :to='{ name: "ModuleViewer", params: { module: module, section: child.id } }'>{{child.name}}</router-link>
							</li>
						</ul>
					</details>
				</summary>
			</details> -->
		</div>
	</div>
</template>

<script>
import { EventBus } from '../ensemble-js/event-bus.js';
import TreeMenu from "./TreeMenu";

export default {
	name: 'ModuleSideNav',
	components: {
		TreeMenu
	},
	props: {
		"module": String,
		"content": Object
	},
	data() {
		return {
			displaySideNav: false
		}
	},
	mounted() {
		EventBus.$on('showSideNav', () => {
			this.displaySideNav = true;
		})
		EventBus.$on('closeSideNav', () => {
			this.displaySideNav = false;
		})
	},
	methods: {
		closeNav() {
			this.displaySideNav = false;
			EventBus.$emit('toggleOverlay');
		}
	}
}
</script>

<style lang="postcss" scoped>
	.side-nav-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		z-index: 20;
		background-color: theme('colors.solid-green');
		padding: 2rem 2.5rem;
		transition: 500ms ease-in;
		.icon-wrapper {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: flex-end;
			margin-bottom: 1rem;
			> * {
				cursor: pointer;
			}
		}
	}
	@screen md {
		.side-nav-wrapper {
			width: 50%;
		}
	}

	@screen lg {
		.side-nav-wrapper {
			width: 35%;
		}
	}
</style>

<template>
	<div class="w-full">
		<div class="grid grid-cols-1 md:grid-cols-2 px-8 py-20 gap-x-8 gap-y-8">
			<section class="font-thin">
				<h2 v-if="!requestSent" class="text-4xl text-burnt-magenta leading-tight mb-4">Setup your new password</h2>
				<div v-else>
					<h2 class="text-4xl mb-4 leading-tight">Success!</h2>
					<p>Your password has been updated. Login <router-link :to='{ name: "Login" }' class="underline text-solid-green">here</router-link>.</p>
				</div>
			</section>
			<section class="">
				<form v-if="!requestSent" @submit.prevent="resetPassword" class="space-y-4">
					<div class="">
						<input type="password" v-model="newPassword" placeholder="Enter a new password" class="form-input" aria-label="Password" aria-describedby="passwordLengthError" autocomplete="new-password" />
						<p v-if="!this.$v.newPassword.minLength" id="passwordLengthError" :class="{'text-red-700': !this.$v.newPassword.minLength}" class="mt-2">Please enter a password that is at least 8 characters long</p>
					</div>
					<div class="">
						<input type="password" v-model="confirmPassword" placeholder="Confirm your new password" class="form-input" aria-label="Confirm Password" aria-describedby="passwordConfirmError"/>
						<p v-if="!this.$v.confirmPassword.sameAsPassword" id="passwordConfirmError" :class="{'text-red-700': !this.$v.confirmPassword.sameAsPassword}" class="mt-2">Please confirm that your new password is correct</p>
					</div>
					<button type="submit" class="w-full px-10 py-4 bg-solid-green text-white text-xl">Reset Password</button>
				</form>
			</section>
		</div>
	</div>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
	name: 'ResetPassword',
	data: function() {
		return {
			requestSent: false,
			token: this.$route.query.token,
			newPassword: null,
			confirmPassword: null
		}
	},
	validations: {
		newPassword: {
			required,
			minLength: minLength(8)
		},
		confirmPassword: {
			sameAsPassword: sameAs('newPassword')
		}
	},
	methods: {
		resetPassword() {
			this.requestSent = true;

			defaultClient.users.resetPassword(this.token, this.newPassword).then().catch(error => {
				console.log(error);
			});
		}
	}
}
</script>

<style scoped lang="postcss">
	.form-input {
		@apply w-full p-2;
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme('colors.cool-gray');
		}
	}
</style>

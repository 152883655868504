<template>
	<div class="w-full">
		<div class="grid grid-cols-1 md:grid-cols-2 px-8 py-12 gap-x-8 gap-y-8">
			<section class="font-thin">
				<h2 class="text-4xl text-burnt-magenta mb-4 leading-tight">Forgot your password?</h2>
				<p v-if="!formSubmitted" class="text-lg">
					After submitting, check your email for steps on how to reset your password.
				</p>
				<p v-else class="text-lg">
					<!-- Which text to use? -->
					<!-- Please check you email for instructions on how to reset your password. -->
					A password reset email has been sent to the following email address: <span class="font-bold text-solid-green">{{ this.emailAddress }}</span>
				</p>
			</section>
			<section class="">
				 <form v-if="!formSubmitted" @submit.prevent="forgotPassword" class="space-y-2">
					<div class="">
						<input v-model="emailAddress" type="text" placeholder="Enter email address" class="mb-2 form-input" aria-label="Email" aria-describedby="emailAddressError" autocomplete="email" />
						<p v-if="!$v.emailAddress.email" id="emailAddressError" class="text-red-700">A valid email address is required</p>
					</div>
					<button type="submit" class="w-full px-10 py-4 bg-solid-green text-white text-xl" :class="{'opacity-50': this.$v.$invalid}" :disabled="this.$v.$invalid">Reset Password</button>
				</form>
			</section>
		</div>
	</div>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";
import { required, email } from 'vuelidate/lib/validators'

export default {
	name: 'ForgotPassword',
	data: function() {
		return {
			emailAddress: null,
			formSubmitted: false
		}
	},
	mounted() {},
	methods: {
		forgotPassword() {
			this.formSubmitted = true;
			defaultClient.users.forgotPassword(this.emailAddress).then().catch(error => {
				console.log(error);
			});
		}
	},
	validations: {
		emailAddress: {
			required,
			email
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
	.form-input {
		@apply w-full p-2;
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme('colors.cool-gray');
		}
	}
</style>

<template>
	<section class="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-16 py-4 lg:py-6">
		<div v-for="(module, index) in modules" :key="index">
			<div class="static-box" :class="{'canadian': module.canadian}" :style="{backgroundColor: colors[index]}">
				<h3 class="text-3xl font-bold">
					{{ module.name }}
				</h3>
				<p class="text-lg font-bold">
					{{ module.title }}
				</p>
				<div v-if="module.canadian" class="canada-text-wrapper">
					<p>
						<font-awesome-icon :icon="['fab', 'canadian-maple-leaf']" fixed-width class="" /> Canada only
					</p>
					<small class="text-xs">Unlocked with the purchase of any module</small>
				</div>
			</div>
			<div class="learning-objectives-wrapper">
				<a :href="learningObjectPath(module)" class="font-bold">Learning Objectives</a>
			</div>
		</div>
	</section>
</template>

<script>
import manifest from "../modules/manifest";

export default {
	name: 'ModulesStatic',
	components: {},
	data() {
		return {
			modules: manifest.modules,
			colors: manifest.colors
		}
	},
	mounted: function() {},
	methods: {
		learningObjectPath(module) {
			return `learning-objectives/${module.path}`;
		}
	}
}
</script>

<style lang="postcss" scoped>
	.static-box {
		--box-text-color: theme('colors.cool-gray');
		width: 100%;
		min-height: 300px;
		padding: 1rem;
		position: relative;
		color: var(--box-text-color);
	}
	.learning-objectives-wrapper {
		@apply mt-2;
	}
	.static-box.canadian {
		--box-text-color: #fff;
		border: 3px solid #fff;
		.canada-text-wrapper {
			position: absolute;
			bottom: 2rem;
		}
	}
</style>

<template>
	<main class="container mx-auto flex justify-center items-center">
		<h2 class="text-3xl text-burnt-magenta">Purchase Cancelled.</h2>
	</main>
</template>

<script>
export default {
	name: 'PurchaseCancelled'
}
</script>

<style lang="postcss" scoped></style>

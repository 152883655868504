<template>
	<div>
		<h1>
			Purchase
			<br />
			<button v-if="!modulesOwned[0]" v-on:click="purchase(1)">Buy Module 1</button>
			<button v-else v-on:click="view(1)">View Module 1</button>
			<br />

			<button v-if="!modulesOwned[1]" v-on:click="purchase(2)">Buy Module 2</button>
			<button v-else v-on:click="view(2)">View Module 2</button>
		</h1>
	</div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
const wsclient = require("../ensemble-js/client.js");
const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_KEY);

export default {
	name: 'Purchase',
	data: function() {
		return {
			modulesOwned: [true, false]
		}
	},
	mounted() {
	},
	methods: {
		purchase: async function(productId) {
			const stripe = await stripePromise;
			wsclient.defaultClient.carts.createCart([productId]).then(x => {
				stripe.redirectToCheckout({
				// Make the id field from the Checkout Session creation API response
				// available to this file, so you can provide it as parameter here
				// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
					sessionId: x.data.sessionId
				}).then(function (result) {
				// If `redirectToCheckout` fails due to a browser or network
				// error, display the localized error message to your customer
				// using `result.error.message`.
				});
			}).catch(error => {
				console.log(error);
			});
		}
	}
}
</script>
<style lang="postcss" scoped>
</style>

<template>
	<div class="tree-navigation">
		<div :style="indentation" class="tree-menu" @click="toggleTree()">
			<font-awesome-icon v-if="depth === 0" :icon="['fas', 'angle-right']" fixed-width class="fa-lg fill-current" title="Arrow" :transform="displayChildren && hasChildren ? { rotate: 90 } : null" />
			<router-link v-if="treeItem.disableNavigation !== true" :to="treeItem.id" v-html="treeItem.name" class="block"></router-link>
			<a href="#" v-else v-html="treeItem.name" class="block"></a>
		</div>
		<div v-if="displayChildren">
			<tree-menu v-for="treeItem in treeNodes" :module="module" :treeItem="treeItem" :depth="depth + 1" :key="treeItem.id" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'TreeMenu',
	props: ['treeItem', 'treeNodes', 'module', 'depth'],
	data: function() {
		return {
			displayChildren: false,
			hasChildren: null
		}
	},
	methods: {
		toggleTree() {
			this.displayChildren = !this.displayChildren;
		},
		checkChildren() {
			if (this.treeNodes !== undefined) {
				this.hasChildren = true;
			}
			return false;
		}
	},
	mounted() {
		this.checkChildren()
	},
	computed: {
		indentation() {
			return {
				'margin-left': `${this.depth * 48}px`
			}
		}
	}
}
</script>

<style lang="postcss" scoped>
	.tree-menu {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0.25rem 0;
		> svg {
			max-width: 10%;
			font-size: 1.45rem;
		}
		> a {
			width: 90%;
			font-size: 1.1rem;
		}
	}
</style>

<template>
	<div class="recap-wrapper space-y-4">
		<div v-for="(item, index) in recap" :key="index" class="recap-box">
			<p class="mb-3 lg:mb-1" v-html="item.text"></p>
			<div v-for="(child, index) in item.children" :key="index">
				<ul v-if="child.type === 'list'" class="list-disc list-outside pl-4">
					<li v-for="(item, index) in child.items" :key="index">
						{{ item }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'RecapItems',
	props: {
		recap: {
			type: Array,
			default() {
				return []
			}
		}
	}
}
</script>
<style lang="postcss" scoped>
	.recap-box {
		@apply w-full p-4;
	}
	.recap-box:nth-child(8n+1) {
		background-color: var(--box-green-100);
	}
	.recap-box:nth-child(8n+2) {
		background-color: var(--box-green-200);
	}
	.recap-box:nth-child(8n+3) {
		background-color: var(--box-green-300);
	}
	.recap-box:nth-child(8n+4) {
		background-color: var(--box-green-400);
	}
	.recap-box:nth-child(8n+5) {
		background-color: var(--box-green-500);
	}
	.recap-box:nth-child(8n+6) {
		background-color: var(--box-green-600);
	}
	.recap-box:nth-child(8n+7) {
		background-color: var(--box-green-700);
	}
	.recap-box:nth-child(n+8) {
		background-color: var(--box-green-800);
	}

	@screen lg {
		.recap-box {
			@apply py-6;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
</style>

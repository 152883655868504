<template>
	<page-header-single-column layoutStyle="recap">
		<template #header>
			<h2>Recap</h2>
		</template>
		<template #content>
			<recap-items :recap="content.recap"></recap-items>
			<slot name="extra-content"></slot>
		</template>
	</page-header-single-column>
</template>

<script>
import ModulePage from '../module-page.js';
import PageHeaderSingleColumn from './layouts/PageHeaderSingleColumn.vue';

export default {
	name: 'Recap',
	props: {
		'content': {
			type: Object
		}
	},
	extends: ModulePage,
	components: {
		'page-header-single-column': PageHeaderSingleColumn
	},
	data() {
		return {}
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="module-table-wrapper" :class="this.contentCenter ? 'md:justify-center' : 'justify-start'">
		<table class="module-table table-auto w-full">
			<thead>
				<tr>
					<th v-for="header in tableData.headers" :key="header" v-html="header">
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, index) in tableData.rows" :key="index">
					<td v-for="value in row" :key="value" v-html="value">
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
export default {
	name: 'ModuleTable',
	props: {
		tableData: {
			type: Object,
			default() {
				return {}
			}
		},
		contentCenter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	}
}
</script>
<style lang="postcss">
	.module-table-wrapper {
		display: flex;
		overflow-x: auto;
	}
	.module-table {
		border-collapse: collapse;
		thead tr th, tbody tr td {
			padding: 1rem;
			border: 2px solid #ffffff;
		}
		thead tr th {
			color: #ffffff;
			background-color: theme('colors.dark-teal');
		}
		tbody tr:nth-child(2n+1) {
			/* Not in tailwind.config.js */
			background-color: #a2b262;
		}
		tbody tr:nth-child(2n+2) {
			/* Not in tailwind.config.js */
			background-color: #c5cd9d;
		}
		tbody tr td {
			color: theme('colors.cool-gray');
			vertical-align: middle;
		}
		caption {
			@apply mt-2;
			caption-side: var(--table-caption-location);
		}
		.table-ul {
			@apply list-disc list-outside pl-4;
		}
		.superscript {
			padding-left: 0.15rem;
		}
	}
	@screen md {
		.module-table {
			tbody tr td {
				vertical-align: top;
			}
		}
	}
</style>

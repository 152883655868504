<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="rlaprairie">
		<template #faculty-title>
			ROBERT LAPRAIRIE, PhD
		</template>
		<template #faculty-details>
			<p>Assistant Professor, College of Pharmacy and Nutrition, University of Saskatchewan</p>
			<p>GlaxoSmithKline Chair in Drug Discovery and Development</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'RLaprairieCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

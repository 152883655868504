import Resource from './resource.js';

export default class QuestionsAndAnswers extends Resource {
    async postQuestion(data) {
        return this.httpPost("questions", data, false);
    }

    async getResponses() {
        return this.httpGet("answers", false);
    }
}

<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="hclarke">
		<template #faculty-title>
			HANCE CLARKE, MD, FRCPC, PhD
		</template>
		<template #faculty-details>
			<p>Associate Professor of Anesthesiology & Pain Medicine, University of Toronto</p>
			<p>Director, Pain Services Toronto General Hospital, University Health Network</p>
			<p>Director, Good Hope Ehlers Danlos Clinic</p>
			<p>Co-Chair Knowledge Translation, University of Toronto Centre for the Study of Pain</p>
			<p>Co-Lead, Centre for Cannabinoid Therapeutics, University Health </p>Network
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'HClarkeCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div v-if="content != null" class="flex flex-col relative">
		<module-nav :content="content" />
		<side-nav :module="module" :content="content" />
		<recap v-if="section === 'recap' && !pageInfo.forcePageComponent" :content="content"></recap>
		<reference-list v-else-if="section === 'references' && !pageInfo.forcePageComponent" :content="content"></reference-list>
		<component :content="content" v-else :is="pageContent"></component>
		<module-pager v-if="pageInfo != null" :module="module" :section="section" :pageInfo="pageInfo" :content="content"></module-pager>
	</div>
</template>

<script>
import ModuleNav from '@/components/ModuleNav.vue';
import ModulePager from '@/components/ModulePager.vue'
import SideNav from '@/components/SideNav.vue'
import Recap from '@/components/Recap.vue'
import ReferenceList from '@/components/ReferenceList.vue'

export default {
	name: 'ModuleViewer',
	data: function() {
		return {
			content: null,
			pageInfo: null
		}
	},
	components: {
		'module-nav': ModuleNav,
		'module-pager': ModulePager,
		'recap': Recap,
		'reference-list': ReferenceList,
		'side-nav': SideNav
	},
	created: function() {
		this.configure();
	},
	methods: {
		flatten(sectionList, flatList) {
			// recursively flatten the nested section/child list
			const self = this;
			sectionList.forEach(function(item) {
				if (item.disableNavigation !== true) {
					flatList.push(item);
				}

				if (item.children != null) {
					self.flatten(item.children, flatList);
				}
			});
		},
		getPageInfo(flatList, sectionId) {
			// generates page info for pager component
			const pageInfo = {};
			flatList.forEach(function(item, index) {
				if (item.id === sectionId) {
					pageInfo.forcePageComponent = item.forcePageComponent;
					pageInfo.prev = (index > 0) ? flatList[index - 1] : null;
					pageInfo.next = (index < flatList.length - 1) ? flatList[index + 1] : null;
				}
			});
			return pageInfo;
		},
		configure: function() {
			this.contentLoad.then(data => {
				if (this.section == null) {
					this.$router.push({ name: 'ModuleViewer', params: { module: this.module, section: data.default.sections[0].id } });
					this.configure();
				}
				else {
					this.content = data.default;
					const flatList = [];
					this.flatten(this.content.sections, flatList);
					this.pageInfo = this.getPageInfo(flatList, this.section);
					this.pageContent = () => import('./modules/' + this.module + '/' + this.section + '.vue');
				}
			});
		}
	},
	computed: {
		contentLoad() {
			return import('./modules/' + this.module + '/content.js');
		},
		module() {
			return this.$route.params.module;
		},
		section() {
			return this.$route.params.section;
		}
	},
	beforeRouteUpdate (to, from, next) {
		next();
		this.configure();
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="background w-full grid grid-cols-1" :style="backgroundStyle">
		<div class="container mx-auto flex items-center px-8">
			<p class="hero-text" v-if="heroText">
				{{ heroText }}
			</p>
			<div class="icon-wrapper" v-if="displayIcon">
				<font-awesome-icon :icon="['fas', 'arrow-down']" class="fa-2x" title="Down Arrow"/>
			</div>
		</div>
	</div>
</template>
<script>
import homeBackground from '../assets/images/home-banner.jpg';
import overviewBackground from '../assets/images/program-overview-banner.jpg';
import aboutProgramBackgrond from '../assets/images/about-program-banner.jpg';
import askTheFacultyBackground from '../assets/images/ask-the-faculty-banner.jpg';

export default {
	name: 'FullWidthBackgroundGeneric',
	props: {
		heroText: {
			type: String,
			default: ''
		},
		displayIcon: {
			type: Boolean,
			default: false
		},
		backgroundType: {
			type: Number,
			default: null
		},
		backgroundYPosition: {
			type: Number
		}
	},
	data: function() {
		return {
			backgrounds: [homeBackground, overviewBackground, aboutProgramBackgrond, askTheFacultyBackground]
		}
	},
	computed: {
		backgroundStyle() {
			return `background: linear-gradient(to top right, rgba(112, 105, 105, 0.3), rgba(204,204,204,0.5)), url(${this.backgrounds[this.backgroundType]}) no-repeat center ${this.formatBgYPos}`;
		},
		formatBgYPos() {
			return !this.backgroundYPosition ? 'center' : `${this.backgroundYPosition}%`
		}
	}
}
</script>
<style lang="postcss" scoped>
	.background {
		position: relative;
		height: 200px;
		/* background-size: cover !important;
		background-position: center center !important; */
		.hero-text {
			@apply leading-tight;
			font-size: 1.75rem;
			font-weight: 600;
			text-align: left;
			color: #fff;
		}
	}
	.icon-wrapper {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: -25px;
		background-color: theme('colors.solid-green');
		color: #fff;
	}

	@screen md {
		.background {
			.hero-text {
				position: absolute;
				bottom: 70px;
				font-size: 2.5rem;
			}
		}
		.icon-wrapper {
			width: 75px;
			height: 75px;
			bottom: calc(75px/2 * -1);
		}
	}

	@screen lg {
		.background {
			height: 350px;
		}
	}
</style>

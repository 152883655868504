<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="fclement">
		<template #faculty-title>
			FIONA CLEMENT, PhD
		</template>
		<template #faculty-details>
			<p>Associate Professor, Cumming School of Medicine, University of Calgary</p>
			<p>Head, Department of Community Health Sciences, University of Calgary</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'FClementCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="w-full">
		<div class="grid grid-cols-1 md:grid-cols-2 px-8 py-20 gap-x-8 gap-y-8">
			<section class="font-thin">
				<h2 class="text-4xl text-burnt-magenta leading-tight mb-4">Login</h2>
				<p class="text-lg">Log in to continue learning.</p>
			</section>
			<section class="">
				<form @submit.prevent="login" class="space-y-4">
					<div class="">
						<input type="text" v-model="username" placeholder="Enter email address" class="form-input" aria-label="Email" autocomplete="email"/>
					</div>
					<div class="">
						<input type="password" v-model="password" placeholder="Enter password" class="form-input" aria-label="Password" autocomplete="current-password"/>
					</div>
					<div class="">
						<p class="text-burnt-magenta">{{ message }}</p>
					</div>
					<div class="">
						<button type="submit" class="w-full px-10 py-4 bg-solid-green text-white text-xl">Log in</button>
					</div>
					<div class="">
						<router-link :to="{name: 'ForgotPassword'}" class="text-burnt-magenta font-bold">Forgot Password?</router-link>
					</div>
				</form>
			</section>
		</div>
	</div>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";

export default {
	name: 'Login',
	data: function() {
		return {
			username: null,
			password: null,
			message: null
		}
	},
	mounted() {
		// console.log(`Login State ${this.$store.state.loggedIn}`);
	},
	methods: {
		login() {
			defaultClient.users.login(this.username, this.password).then(() => {
				this.$store.commit('setLoggedIn');
				console.log(`Login State ${this.$store.state.loggedIn}`);
				this.$router.push({ name: 'Overview' });
			}).catch(() => {
				this.message = 'Invalid credentials, please check your username or password';
			});
		}
	}
}
</script>

<style lang="postcss" scoped>
	.form-input {
		@apply w-full p-2;
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme('colors.cool-gray');
		}
	}
</style>

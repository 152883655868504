<template>
	<div class="w-full">
		<div class="grid grid-cols-1 md:grid-cols-2 px-8 py-20 gap-x-8 gap-y-8">
			<section class="font-thin">
				<h2 class="text-4xl text-burnt-magenta leading-tight mb-4">Registration</h2>
				<p class="text-lg">Set up an account to access the program.</p>
			</section>
			<section>
				<form class="space-y-4" @submit.prevent="register">
					<div class="">
						<input v-model="firstName" @input="$v.firstName.$touch" placeholder="Enter your first name" class="form-input" aria-label="First Name" autocomplete="given-name" aria-describedby="invalidfirstNameError" />
						<p v-if="$v.firstName.$invalid && $v.firstName.$dirty" id="invalidfirstNameError" class="error-message">
							Please enter a first name.
						</p>
					</div>
					<div class="">
						<input v-model="lastName" @input="$v.lastName.$touch" placeholder="Enter your last name" class="form-input" aria-label="Last Name" autocomplete="family-name" aria-describedby="invalidlastNameError"/>
						<p v-if="$v.lastName.$invalid && $v.lastName.$dirty" id="invalidlastNameError" class="error-message">
							Please enter a last name.
						</p>
					</div>
					<div class="">
						<input v-model="email" @input="$v.email.$touch" placeholder="Enter your email address" class="form-input" aria-label="Email" autocomplete="email" aria-describedby="invalidEmailError"/>
						<p v-if="!$v.email.email && $v.email.$dirty" id="invalidEmailError" class="error-message">
							Please ensure your email is valid.
						</p>
					</div>
					<div class="">
						<select v-model="country" name="country" id="country" class="form-input" aria-label="Country">
							<option value="" disabled selected>Select your location</option>
							<option value="CA">Canada</option>
							<option value="International">International</option>
						</select>
					</div>
					<div class="">
						<input v-model="password" @input="$v.password.$touch" type="password" placeholder="Choose a password" class="form-input" aria-label="Password" autocomplete="new-password" aria-describedby="invalidPasswordError"/>
						<p v-if="$v.password.$invalid && $v.password.$dirty" id="invalidPasswordError" class="error-message">
							Please enter a valid password.
						</p>
					</div>
					<div class="">
						<input v-model="confirmPassword" @input="$v.confirmPassword.$touch" type="password" placeholder="Confirm your password" class="form-input" aria-label="Confirm Password" aria-describedby="passwordConfirmError" />
						<p v-if="!$v.confirmPassword.sameAsPassword && $v.confirmPassword.$dirty" id="passwordConfirmError" class="error-message">Please ensure that your passwords match.</p>
					</div>
					<div class="">
						<input v-model="coupon" placeholder="Enter your coupon code" class="form-input" aria-label="Coupon" />
					</div>
					<div class="p-1">
						<input type="checkbox" name="communications" id="communications" class="mr-2">
						<label for="communications">Site Communications Opt-in Text</label>
					</div>
					<div class="">
						<p class="error-message">{{ serverErrorMessage }}</p>
					</div>
					<button type="submit" class="submit-button" :class="{'submit-button--disabled': $v.$invalid}" :disabled="$v.$invalid">Register</button>
				</form>
			</section>
		</div>
	</div>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
	name: 'RegistrationForm',
	data: function () {
		return {
			inviteToken: null,
			validToken: false,
			firstName: "",
			lastName: "",
			country: "",
			email: "",
			password: "",
			confirmPassword: "",
			coupon: "",
			remember: false,
			submitted: false,
			hasError: false,
			serverErrorMessage: "",
			registered: false
		};
	},
	validations: {
		firstName: { required },
		lastName: { required },
		email: { required, email },
		country: { required },
		password: { required },
		confirmPassword: {
			sameAsPassword: sameAs('password')
		}
	},
	mounted: function() {
		this.inviteToken = this.$route.query.invite;
		if (this.inviteToken != null) {
			this.validToken = true;
		}
	},
	methods: {
		register: function () {
			this.serverErrorMessage = "";
			this.submitted = true;

			const extProperties =
				[
					//{ propertyId: this.trialRole }
				];

			defaultClient.users.register(
				this.firstName,
				this.lastName,
				this.email,
				this.password,
				this.country,
				extProperties,
				this.inviteToken,
				this.coupon
			).then(() => {
				// track in GA
				//const eventLabel = this.firstName + ' ' + this.lastName + ' (' + this.email + ') Session: ' + this.session + ' Role: ' + this.trialRole;
				//this.$ga.event('accounts', 'registration', eventLabel, 101);

				// automatically login user
				defaultClient.users.login(this.email, this.password, true).then(() => {
					this.$store.commit('setLoggedIn');
					this.registered = true;
					this.$router.push({ name: 'Overview' });
				}).catch(error => {
					console.log(error);
					this.hasError = true;
					this.serverErrorMessage = "Registration error, please contact support.";
				});
			}).catch(error => {
				this.hasError = true;
				console.log(error.response);
				if (error.response.status === 409) {
					this.serverErrorMessage = "The provided email is already registered.";
				}
				else {
					console.log(error.response);
					this.serverErrorMessage = error.response.data;
				}
			});
		}
	},
	computed: {

	}
};
</script>

<style lang="postcss" scoped>
	.form-input {
		@apply w-full p-2;
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme('colors.cool-gray');
		}
		&--error {
			border-color: theme('colors.red.700');
		}
	}
	.error-message {
		@apply mt-2;
		color: theme('colors.red.700');
	}
	.submit-button {
		@apply w-full px-10 py-4 bg-solid-green text-white;
		&--disabled {
			--bg-opacity: 0.7;
		}
	}
</style>

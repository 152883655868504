<template>
	<section v-if="question != null" class="grid grid-cols-1 py-8" :class="setXPadding">
		<article>
			<p class="quiz-question-number">Question {{question.order}}</p>
			<h2 class="quiz-question-header" v-html="question.question.questionText"></h2>
		</article>
		<article class="space-y-4">
			<div v-for="(answer, index) in question.question.answers" :key="answer.id"
				 :class="[answer.isCorrect ? 'correct' : '', answer.isIncorrect ? 'incorrect' : '', answer.isSelected ? 'selected' : '', defaultCursor ? 'cursor-default' : 'cursor-pointer']"
				 v-on:click="selectAnswer(answer)" class="quiz-answer-wrapper">
				<div class="quiz-content-wrapper">
					<p class="quiz-answer-letter">{{answerLetters[index]}}</p>
					<p class="quiz-answer-text">{{answer.text}}</p>
				</div>
				<div class="quiz-icon-wrapper">
					<font-awesome-icon :icon="['fas', 'check']" size="2x" v-if="answer.isCorrect" />
					<font-awesome-icon :icon="['fas', 'times']" size="2x" v-if="answer.isIncorrect" />
				</div>
			</div>
		</article>
		<article class="mt-4 md:mt-6">
			<p v-if="question.explanation != null" v-html="question.explanation"></p>
		</article>
	</section>
</template>

<script>

export default {
	name: 'QuizQuestion',
	props: {
		question: {
			type: Object
		},
		readOnly: {
			type: Boolean
		},
		removeXPadding: {
			type: Boolean
		}
	},
	components: {},
	data: function() {
		return {
			answerLetters: ['A', 'B', 'C', 'D', 'E', 'F'],
			selectedAnswer: null,
			locked: false,
			defaultCursor: false
		}
	},
	mounted: function() {
		if (this.readOnly) {
			this.setLock(true);
			this.setCursor(true);
		}
	},
	methods: {
		refresh: function() {
			this.$forceUpdate();
		},
		setLock: function(state) {
			this.locked = state;
		},
		setCursor: function(state) {
			this.defaultCursor = state;
		},
		selectAnswer: function(answer) {
			if (!this.locked) {
				if (this.selectedAnswer != null) {
					this.selectedAnswer.isSelected = false;
				}
				answer.isSelected = true;
				this.selectedAnswer = answer;
				this.$emit('selectionChanged', this.selectedAnswer);
				this.$forceUpdate();
			}
		}
	},
	computed: {
		setXPadding() {
			return this.removeXPadding ? 'px-0' : 'px-4';
		}
	}
}
</script>

<style lang="postcss" scoped>
	/* Quiz Components */
	.quiz-question-number {}

	.quiz-question-header {
		@apply text-3xl text-burnt-magenta leading-tight mt-2 mb-6;
	}

	.quiz-answer-wrapper {
		@apply w-full text-cool-gray px-4 py-4;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 2px solid theme('colors.cool-gray');
		transition: all 0.3s;
		/* &:hover {
			cursor: pointer;
		} */
		.quiz-answer-letter {
			@apply font-bold;
		}
	}

	.quiz-content-wrapper {
		max-width: 90%;
	}

	/* Answer States */
	.quiz-answer-wrapper.selected {
		@apply text-white bg-cool-gray;
	}

	.quiz-answer-wrapper.correct {
		@apply text-white bg-solid-green;
	}

	.quiz-answer-wrapper.incorrect {
		@apply text-white bg-burnt-magenta;
	}

	@screen md {
		.quiz-answer-wrapper {
			@apply px-6 py-6;
		}
	}
</style>

<template>
	<main class="container mx-auto flex justify-center items-center">
		<h2 v-if="success" class="text-3xl text-burnt-magenta">Thank you for verifying your email.</h2>
		<h2 v-if="error" class="text-3xl text-burnt-magenta">Invalid email address or verification link.</h2>
	</main>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";

export default {
	name: 'EmailVerify',
	data: function() {
		return {
			success: false,
			error: false
		}
	},
	mounted: function() {
		defaultClient.users.verifyEmail(this.email, this.token).then(() => {
			console.log("Email verified");
			this.success = true;
		}).catch(() => {
			console.log("Error verifying email");
			this.error = true;
		});
	},
	computed: {
		email() {
			return this.$route.params.email;
		},
		token() {
			return this.$route.params.token;
		}
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<main>
		<results-banner backgroundColor="#5c7f71" textColor="#fff">
			<template #col-left>
				<h2 class="font-bold text-xl">
					Got a question about the module?
				</h2>
				<p class="text-xl">
					Feel free to participate in the Q&A
				</p>
			</template>
			<template #col-right>
				<router-link :to="{ name: 'AskTheFaculty'}" class="block w-full py-4 bg-white text-dark-teal text-center text-2xl">Ask the faculty</router-link>
			</template>
		</results-banner>
		<div class="container mx-auto">
			<section class="grid grid-cols-1 px-4 py-8 md:px-0">
				<article v-if="results != null" class="space-y-3">
					<p>
						Results: <span class="font-bold">{{ results.correctCount }}/{{ results.answeredCount }}</span>
					</p>
					<div v-if="this.results.passed">
						<p class="text-solid-green text-2xl">
							Great job!
						</p>
						<!-- Survey Component -->
						<satisfaction-survey :quizId="surveyQuizId" v-if="surveyQuizId != null" />
						<div v-if="quiz != null">
							<quiz-question v-for="(question, index) in quiz.questions" :key="index" :question="question" :readOnly="true" :removeXPadding="true" />
						</div>
						<div class="flex justify-center">
							<!-- Temporary route links -->
							<router-link :to="{ name: 'Overview'}" class="block w-full md:w-6/12 px-2 py-4 bg-solid-green text-white text-center text-lg md:text-2xl ">Back to modules overview</router-link>
						</div>
					</div>
					<div v-else class="space-y-4">
						<p class="text-burnt-magenta text-2xl">
							Let's try again.
						</p>
						<div class="grid grid-cols-1 space-y-4 md:grid-cols-2 md:gap-x-8 md:space-y-0">
							<!-- Temporary route links -->
							<router-link :to="{ name: 'Quiz'}" class="block w-full px-2 py-4 bg-solid-green text-white text-center text-lg md:text-2xl ">Retake the test</router-link>
							<router-link :to="{ name: 'ModuleViewer'}" class="block w-full px-2 py-4 bg-burnt-magenta text-white text-center text-lg md:text-2xl ">Back to module</router-link>
						</div>
					</div>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import ResultsBanner from "../components/FullWidthTwoColumnBanner";
import QuizQuestion from '@/components/QuizQuestion.vue'
import SatisfactionSurvey from '@/components/SatisfactionSurvey.vue';
const wsClient = require("../ensemble-js/client.js");

export default {
	name: 'QuizResultsPage',
	components: {
		'results-banner': ResultsBanner,
		'quiz-question': QuizQuestion,
		'satisfaction-survey': SatisfactionSurvey
	},
	data: function() {
		return {
			results: null,
			quiz: null,
			surveyQuizId: null
		}
	},
	mounted: function() {
		this.contentLoad.then(content => {
			this.surveyQuizId = content.default.quiz.surveyQuizId;

			wsClient.defaultClient.quizzes.sessionResults(this.sessionId).then(response => {
				console.log(response.data);
				// setup isCorrect/isIncorrect on each question for display
				response.data.quiz.questions.forEach(function(item) {
					item.question.answers.find(x => x.id === item.question.correctAnswerId).isCorrect = true;
				});

				this.quiz = response.data.quiz;
				this.results = response.data;

				// setup explanations
				this.quiz.questions.forEach(function(question, index) {
					question.explanation = content.default.quiz.explanations[index];
					// console.log(question.explanation);
				});
			}).catch(error => {
				console.log(error);
			});
		});
	},
	computed: {
		contentLoad() {
			return import('@/components/modules/' + this.module + '/content.js');
		},
		module() {
			return this.$route.params.module;
		},
		sessionId() {
			return this.$route.params.sessionId;
		}
	}
}
</script>

<template>
	<div class="unordered-list-wrapper">
		<ul class="space-y-1">
			<li v-for="(listItem, index) in contentList" :key="index" v-html="listItem">
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'ModuleUnorderedList',
	props: {
		list: Array,
		default() {
			return null;
		}
	},
	data() {
		return {
			contentList: this.list
		}
	}
}
</script>
<style lang="postcss">
	.unordered-list-wrapper {
		> ul {
			@apply list-disc list-outside pl-6;
		}
	}
</style>

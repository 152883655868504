<template>
	<div class="module-pager">
		<div class="container mx-auto flex justify-end">
			<div class="icon-container space-x-4">
				<router-link v-if="pageInfo.prev != null" :to='{ name: "ModuleViewer", params: { module: module, section: pageInfo.prev.id }}'>
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'arrow-left']" transform="shrink-4" class="fa-2x"/>
					</div>
				</router-link>
				<router-link v-if="pageInfo.next.id === 'quiz'" :to='{ name: "Quiz", params: { module: module }}'>
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'arrow-right']" transform="shrink-4" class="fa-2x"/>
					</div>
				</router-link>
				<router-link v-else-if="pageInfo.next != null" :to='{ name: "ModuleViewer", params: { module: module, section: pageInfo.next.id }}'>
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'arrow-right']" transform="shrink-4" class="fa-2x"/>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModulePager',
	props: [
		'content',
		'module',
		'section',
		'pageInfo'
	],
	data: function() {
		return {};
	},
	mounted: function() {
	},
	methods: {
	}
}
</script>
<style lang="postcss" scoped>
	.module-pager {
		@apply bg-light-green;
		width: 100%;
		padding: 1rem;
		.icon-container {
			display: flex;
		}
		.icon-wrapper {
			width: 45px;
			height: 45px;
			position: relative;
			background-color: theme('colors.cool-gray');
				> * {
				width: 100%;
				height: 100%;
				color: #fff;
			}
		}
	}
</style>

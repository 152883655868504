<template>
	<div id="about-faculty">
		<div class="container mx-auto">
			<section class="grid grid-cols-1 px-8 pt-12 pb-4">
				<article>
					<h2 class="text-3xl text-burnt-magenta mb-4">Our faculty</h2>
				</article>
			</section>
			<section class="grid grid-cols-1 gap-y-6 lg:grid-cols-3 lg:gap-16 px-8 pb-12">
				<lbalneaves-card :readMore="true" coiUrl="CCS_Disclosures_Lynda_Balneaves.pdf" />
				<hclarke-card :readMore="true" coiUrl="CCS_Disclosures_Hance_Clarke.pdf" />
				<fclement-card :readMore="true" coiUrl="CCS_Disclosures_Fiona_Clement.pdf" />
				<zcooper-card :readMore="true" coiUrl="CCS_Disclosures_Ziva_Cooper.pdf" />
				<mhill-card :readMore="true" coiUrl="CCS_Disclosures_Matthew_Hill.pdf" />
				<chillard-card :readMore="true" coiUrl="CCS_Disclosures_Cecillia_Hillard.pdf" />
				<rlaprairie-card :readMore="true" coiUrl="CCS_Disclosures_Robert_Laprairie.pdf" />
				<blefoll-card :readMore="true" coiUrl="CCS_Disclosures_Bernard_Le_Foil.pdf" />
				<ksharkey-card :readMore="true" coiUrl="CCS_Disclosures_Keith_Sharkey.pdf" />
			</section>
			<section class="grid grid-cols-1 px-8 py-12">
				<article class="space-y-4 italic">
					<p>
						This activity is an Accredited Self-Assessment Program (Section 3) as defined by the Maintenance of Certification Program of the Royal College of Physicians and Surgeons of Canada, and approved by the University of Calgary Office of Continuing Medical Education and Professional Development. You may claim a maximum of <strong>9 hours</strong> (credits are automatically calculated).
					</p>
					<p>
						<strong>Claiming your credits:</strong> Visit MAINPORT <a href="https://mainport.royalcollege.ca" target="_blank" class="underline">https://mainport.royalcollege.ca</a> to record your learning and outcomes using SAP ID: 00013421.
					</p>
					<p>
						Through an agreement between the Royal College of Physicians and Surgeons of Canada and the American Medical Association, physicians may convert Royal College MOC credits to AMA PRA Category 1 Credits<sup>&trade;</sup>. Information on the process to convert Royal College MOC credit to AMA credit can be found at <a target="_blank" class="underline" href="https://edhub.ama-assn.org/pages/applications">https://edhub.ama-assn.org/pages/applications</a>
					</p>
				</article>
			</section>
		</div>
	</div>
</template>
<script>
import hClarkeCard from '../faculty/hclarke-card.vue';
import lBalneavesCard from '../faculty/lbalneaves-card.vue';
import fClementCard from '../faculty/fclement-card.vue';
import zCooperCard from '../faculty/zcooper-card.vue';
import mHillCard from '../faculty/mhill-card.vue';
import cHillardCard from '../faculty/chillard-card.vue';
import rLaprairieCard from '../faculty/rlaprairie-card.vue';
import bLefollCard from '../faculty/blefoll-card.vue';
import kSharkeyCard from '../faculty/ksharkey-card.vue';

export default {
	name: 'AboutFacultyContent',
	components: {
		'hclarke-card': hClarkeCard,
		'lbalneaves-card': lBalneavesCard,
		'fclement-card': fClementCard,
		'zcooper-card': zCooperCard,
		'mhill-card': mHillCard,
		'chillard-card': cHillardCard,
		'rlaprairie-card': rLaprairieCard,
		'blefoll-card': bLefollCard,
		'ksharkey-card': kSharkeyCard
	}
}
</script>

import Users from "./users.js";
import Poll from "./poll.js";
import QuestionsAndAnswers from "./questions-and-answers.js";
import Videos from "./videos.js";
import Properties from "./properties.js";
import Emails from "./emails.js";
import Carts from "./carts.js";
import Quizzes from "./quizzes.js";
import Meetings from "./meetings.js";
import Documents from "./documents.js";

const axios = require("axios");

const wsUrl = process.env.VUE_APP_WEB_SERVICE_URL;
const wsAxios = axios.create({
    baseURL: wsUrl
});

const cmsUrl = process.env.VUE_APP_CMS_URL;
const strapiAxios = axios.create({
    baseURL: cmsUrl
});

class Client {
    constructor(axios) {
        this.axios = axios;
        this.users = new Users(axios);
        this.poll = new Poll(axios);
        this.properties = new Properties(axios);
        this.emails = new Emails(axios);
		this.carts = new Carts(axios);
		this.quizzes = new Quizzes(axios);
		this.documents = new Documents(axios);
    }
}

class CMSClient {
	constructor(axios) {
		this.axios = axios;
        this.videos = new Videos(axios);
        this.questionsAndAnswers = new QuestionsAndAnswers(axios);
		this.meetings = new Meetings(axios);
	}
}

export const defaultClient = new Client(wsAxios);
export const cmsClient = new CMSClient(strapiAxios);

<template>
	<main class="container mx-auto">
		<component :is="component"></component>
		<div class="back-button-wrapper">
			<a @click="$router.back()">Back</a>
		</div>
	</main>
</template>

<script>
	export default {
		name: 'LearningObjectives',
		data() {
			return {
				component: null
			};
		},
		created() {
			this.component = () => import('../components/modules/' + this.module + '/learning-objectives.vue');
		},
		computed: {
			module() {
				return this.$route.params.module;
			}
		}
	}
</script>

<style lang="postcss" scoped>
	.back-button-wrapper > * {
		@apply font-bold cursor-pointer;
	}
</style>

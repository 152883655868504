<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="chillard">
		<template #faculty-title>
			CECILIA HILLARD, PhD
		</template>
		<template #faculty-details>
			<p>Director, Neuroscience Research Center, Medical College of Wisconsin</p>
			<p>Professor, Department of Pharmacology and Toxicology, Medical College of Wisconsin</p>
			<p>Past Executive Director, International Cannabinoid Research Society</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'FClementCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="w-full">
		<div class="grid grid-cols-1 md:grid-cols-2 px-8 py-20 gap-x-8 gap-y-8">
			<section class="font-thin">
				<h2 class="text-4xl text-burnt-magenta leading-tight mb-4">Account Settings</h2>
			</section>
			<section>
				<form class="space-y-4" @submit.prevent="updateAccount">
					<div class="">
						<input v-model="firstName" placeholder="Enter your first name" class="form-input" aria-label="First Name" />
					</div>
					<div class="">
						<input v-model="lastName" placeholder="Enter your last name" class="form-input" aria-label="Last Name" />
					</div>
					<div class="">
						<input v-model="email" placeholder="Enter your email address" class="form-input" :class="{ 'form-input--error': !$v.email.email }" aria-label="Email" aria-describedby="emailValidError"/>
						<p v-if="!$v.email.email" id="emailValidError" class="text-red-700 mt-2">Please enter a valid email address.</p>
					</div>
					<button type="submit" class="w-full px-10 py-4 bg-solid-green text-white">Update profile</button>
				</form>
				<p class="text-red-700 mt-2">{{ message }}</p>
			</section>
		</div>
	</div>
</template>

<script>
import { defaultClient } from "../ensemble-js/client.js";
import { required, email } from "vuelidate/lib/validators";

export default {
	name: 'AccountSettings',
	data: function () {
		return {
			firstName: "",
			lastName: "",
			email: "",
			message: null
		};
	},
	mounted: function() {
		const self = this;
		defaultClient.users.getCurrentUser().then(user => {
			self.firstName = user.data.firstName;
			self.lastName = user.data.lastName;
			self.email = user.data.email;
		}).catch(error => {
			console.log(error);
		});
	},
	methods: {
		updateAccount() {
			defaultClient.users.updateUser({
				firstName: this.firstName,
				lastName: this.lastName
			}).then(() => {
				this.message = null;
				this.$router.push({ name: 'Overview' });
			}).catch(() => {
				this.message = "Error updating profile";
			});
		}
	},
	validations: {
		firstName: {},
		lastName: {},
		email: { email }
	}
};
</script>

<style lang="postcss" scoped>
	.form-input {
		@apply w-full p-2;
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme(colors.cool-gray);
		}
		&--error {
			border-color: theme('colors.red.700');
			&:focus {
				border-color: theme('colors.red.700');
			}
		}
	}
</style>

<template>
	<a :href="anchorLink" target="_blank" rel="noopener" :title="title" class="module-anchor">{{content}}</a>
</template>
<script>
export default {
	name: 'ModuleAnchor',
	props: {
		anchorText: {
			type: String,
			default: null
		},
		anchorLink: {
			type: String,
			default: '#'
		}
	},
	data() {
		return {
			content: this.anchorText,
			title: this.anchorText == null ? this.anchorLink : this.anchorText
		}
	}
}
</script>
<style lang="postcss" scoped>
	.module-anchor {
		font-size: inherit;
		color: theme('colors.solid-green');
		text-decoration: underline;
	}
</style>
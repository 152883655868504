import Resource from './resource.js';

export default class Users extends Resource {
    async login(username, password) {
        const response = await this.axios.post('users/tokens', {
            username: username,
            password: password
        });

        const token = response.data.token;
        localStorage.setItem(process.env.VUE_APP_TOKEN_STORAGE_NAME, token);

		return response;
    }

	logout() {
		localStorage.removeItem(process.env.VUE_APP_TOKEN_STORAGE_NAME);
	}

	isLoggedIn() {
		return localStorage.getItem(process.env.VUE_APP_TOKEN_STORAGE_NAME) != null;
	}

    async register(firstName, lastName, email, password, country, extProperties, inviteToken, coupon) {
        return await this.axios.post("users", {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            country: country,
			extProperties: extProperties,
			inviteToken: inviteToken,
			coupon: coupon
        });
    }

    async registerWithData(regInfo) {
        return await this.axios.post("users", regInfo);
	}

    async getCurrentUser() {
        return this.httpGet('users/me', true);
    }

    async updateUser(user) {
        return this.httpPut('users/me', user, true);
    }

    async forgotPassword(emailAddress) {
        await this.axios.post('users/tokens/password', {
            emailAddress: emailAddress
        });
    }

    async resetPassword(token, password) {
        await this.axios.put('users/tokens/password', {
			token: token,
			password: password
        });
    }

    async verifyEmail(emailAddress, token) {
        await this.axios.put('users/tokens/email', {
			token: token,
			emailAddress: emailAddress
        });
    }

	async purchases() {
        return await this.httpGet('users/purchases', true);
	}
}

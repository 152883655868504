<template>
	<main>
		<full-width-bg-generic displayIcon heroText="Meet our team of experts" :backgroundType="2" />
		<div class="container mx-auto">
			<section class="grid grid-cols-1 md:grid-cols-10 gap-x-8 gap-y-8 px-8 pt-20 pb-12">
				<component :is="facultyCard" class="col-span-3" />
				<component :is="facultyBio" class="col-span-7" />
			</section>
		</div>
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue'

export default {
	name: 'FacultyMember',
	data: function() {
		return {
			facultyCard: null,
			facultyBio: null
		}
	},
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric
	},
	created: function() {
		this.configure();
	},
	methods: {
		configure: function() {
			this.facultyCard = () => import('@/components/faculty/' + this.member + '-card.vue');
			this.facultyBio = () => import('@/components/faculty/' + this.member + '-bio.vue');
		}
	},
	computed: {
		member() {
			return this.$route.params.member;
		}
	}
}
</script>

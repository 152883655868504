<template>
	<div class="banner-wrapper" :style="cssVariables">
		<section class="container mx-auto">
			<div class="grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-x-16 px-4 py-8 md:px-8">
				<article>
					<slot name="col-left"></slot>
				</article>
				<article>
					<slot name="col-right"></slot>
				</article>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'FullWidthTwoColumnBanner',
	props: {
		backgroundColor: {
			type: String,
			default: '#fff'
		},
		textColor: {
			type: String,
			default: '#3b3c40'
		}
	},
	computed: {
		cssVariables() {
			return {
				'--background-color': this.backgroundColor,
				'--text-color': this.textColor
			};
		}
	}
}
</script>

<style lang="postcss" scoped>
	.banner-wrapper {
		@apply w-full;
		background-color: var(--background-color);
		color: var(--text-color);
	}
</style>
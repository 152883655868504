<template>
	<section class="grid grid-cols-1 lg:grid-cols-3 lg:gap-16 lg:px-8" v-if="!loading">
		<module-box v-for="(module, index) in manifest.modules"
			:key="module.productId" :canadian="module.canadian"
			:productId="module.productId" :module="module"
			:state="module.state" :bgColor="manifest.colors[index]" />
	</section>
</template>

<script>
	import { defaultClient } from "../ensemble-js/client";
	import Modulebox from '@/components/ModuleBox.vue';

	export default {
		name: 'Modules',
		components: {
			'module-box': Modulebox
		},
		data() {
			return {
				loading: true,
				purchases: null,
				manifest: null,
				requiredQuizzes: {
					CA: [1, 3, 5, 7, 9, 11, 13, 15, 17],
					Intl: [1, 3, 5, 7, 9, 11, 13, 15]
				}
			}
		},
		mounted: function() {
			const readyEvent = 'modules-ready';

			const self = this;
			this.manifestLoad.then(r => {
				self.manifest = r.default;

				defaultClient.users.purchases().then(x => {
					self.purchases = x.data;

					if (self.purchases != null) {
						self.manifest.modules.forEach(function(module) {
							self.purchases.forEach(function(purchase) {
								if (module.productId === purchase.productId) {
									module.state = "purchased";
								}
							});
						});
					}

					// check completions
					defaultClient.quizzes.getSessionStates().then(q => {
						const sessions = q.data;

						// check if modules are completed
						const isCanadian = q.data.user.country === "CA";
						const requiredQuizzes = isCanadian ? this.requiredQuizzes.CA : this.requiredQuizzes.Intl;
						if (!isCanadian) {
							this.manifest.modules = this.manifest.modules.filter(m => !m.canadian);
						}

						// check if certificate download shouls show
						let completedAll = true;
						requiredQuizzes.forEach(quizId => {
							const sessionStates = q.data.sessionStates;
							if (sessionStates.filter(e => e.quizId === quizId && e.sessionState === 2).length === 0) {
								completedAll = false;
							}
						});

						self.manifest.modules.forEach(function(module) {
							sessions.sessionStates.forEach(session => {
								if (session.quizId === module.quizId && session.sessionState === 2) {
									module.state = "completed";
								}
							});
						});

						self.loading = false;
						this.$emit(readyEvent, {
							sessions: sessions,
							completedAll: completedAll,
							showBundle: self.purchases.length < 8
						});
					});
				}).catch(error => {
					console.log(error);
				});
			});
		},
		computed: {
			manifestLoad() {
				return import('./modules/manifest.js');
			}
		}
	}
</script>

<style lang="postcss">
</style>

<template>
	<div class="w-full">
		<section class="grid grid-cols-1 md:grid-cols-2 px-8 pt-12 md:pt-20 pb-12 gap-x-8 gap-y-8 border-b-4 border-light-green">
			<article class="font-thin">
				<h2 class="text-4xl leading-tight text-burnt-magenta mb-4">Got a question?</h2>
				<p v-if="!formSubmitted" class="">
					Review what others have asked, or submit your own question and a member of our faculty will respond within two weeks.
					<br>
					<br>
					Please check back to see our response.
				</p>
				<p v-else class="">
					Your message to the faculty has been sent!
				</p>
			</article>
			<article class="">
				 <form v-if="!formSubmitted" class="space-y-4" @submit.prevent="submitMessage">
					<div class="">
						<textarea v-model="facultyMessage" @input="$v.facultyMessage.$touch" name="facultyMessage" id="facultyMessage" cols="30" rows="10" placeholder="Type your question here" class="w-full p-2 mb-2 form-input" aria-label="Message Faculty" aria-describedby="facultyMessageError"></textarea>
						<p v-if="$v.facultyMessage.$dirty && !$v.facultyMessage.required" id="facultyMessageError" class="text-red-700">A message is required</p>
					</div>
					<button type="submit" class="w-full px-10 py-4 bg-solid-green text-white text-xl" :class="{'opacity-50': this.$v.$invalid}" :disabled="this.$v.$invalid">Submit</button>
				</form>
			</article>
		</section>
		<section class="grid grid-cols-1 px-8 py-12 gap-y-8" v-if="showQuestions">
			<article>
				<h2 class="text-4xl leading-tight text-burnt-magenta mb-4">
					What others have asked
				</h2>
				<div class="space-y-4" >
					<div v-for="(q, index) in questions" :key="q.id" class="qa-item-wrapper">
						<h3 class="mb-2 font-bold">Q{{index+1}}. {{q.question.question}}</h3>
						<p>A{{index+1}}. {{q.answer}}</p>
					</div>
				</div>
			</article>
		</section>
	</div>
</template>

<script>
import { defaultClient, cmsClient } from "../ensemble-js/client";
import { required } from 'vuelidate/lib/validators'

export default {
	name: 'AskTheFacultyForm',
	data: function() {
		return {
			user: null,
			questions: null,
			showQuestions: false,
			facultyMessage: '',
			formSubmitted: false
		}
	},
	mounted() {
		// load current user
		defaultClient.users.getCurrentUser().then(user => {
			this.user = user.data;
		});

		// load responses
		cmsClient.questionsAndAnswers.getResponses().then(result => {
			this.questions = result.data;
			this.showQuestions = this.questions != null && this.questions.length > 0;
		}).catch(error => {
			console.log(error);
		});
	},
	methods: {
		submitMessage() {
			const name = `${this.user.firstName} ${this.user.lastName}`;
			cmsClient.questionsAndAnswers.postQuestion({ name: name, email: this.user.email, question: this.facultyMessage })
					 .then(() => {
						 this.formSubmitted = true;
						 defaultClient.emails.notify({ name: name, email: this.user.email, question: this.facultyMessage });
					 }).catch(() => {
					 });
		}
	},
	validations: {
		facultyMessage: {
			required
		}
	}
}
</script>

<style lang="postcss" scoped>
	.form-input {
		border: 3px solid #ccc;
		&:focus {
			border: 3px solid theme('colors.cool-gray');
		}
	}
</style>

<template>
	<nav class="module-main-nav">
		<div class="container mx-auto grid grid-cols-10">
			<div class="left-wrapper col-span-2">
				<font-awesome-icon @click="toggleSideNav" :icon="['fas', 'list-ul']" class="fa-2x cursor-pointer" title="Menu Icon"/>
			</div>
			<div class="right-wrapper col-span-8">
				<p class="text-xl font-bold text-burnt-magenta">{{content.name}}</p>
			</div>
		</div>
	</nav>
</template>

<script>
import { EventBus } from '../ensemble-js/event-bus.js';

export default {
	name: 'ModuleNav',
	props: ['content'],
	methods: {
		toggleSideNav() {
			EventBus.$emit('showSideNav');
		}
	}
}
</script>
<style lang="postcss" scoped>
	.module-main-nav {
		@apply w-full;
		@apply sticky;
		@apply px-4 py-6;
		top: 100px;
		background-color: theme('colors.light-green');
		z-index: 5;
		.left-wrapper {
			display: flex;
			align-items: center;
		}
		.right-wrapper {
			display: flex;
			align-items: center;
		}
	}
	@screen md {
		.module-main-nav {
			top: 100px;
			@apply px-4;
			.right-wrapper {
				justify-content: flex-end;
			}
		}
	}
</style>

import ModuleAnchor from './components/ModuleAnchor.vue';
import ModuleFigure from './components/ModuleFigure.vue';
import ModuleCard from './components/ModuleCard.vue';
import ModuleCardBasic from './components/ModuleCardBasic.vue';
import ModuleUnorderedList from './components/ModuleUnorderedList.vue';
import ModuleSVG from './components/ModuleSVG.vue';
import ModuleVideo from './components/ModuleVideo.vue';
import ModuleTable from './components/ModuleTable.vue';
import TwoLineHeader from './components/TwoLineHeader.vue';
import RecapItems from './components/RecapItems.vue';
import PageSectionHeader from './components/layouts/PageSectionHeader.vue';
import ContentTwoColumnGeneric from './components/layouts/ContentTwoColumnGeneric.vue';
import Reference from './components/Reference.vue';

export default {
	data: function() {
		return {
			'styles': {
				'overrides': {
					'marginTop': 'margin-top: 2px',
					'marginBottom': 'margin-bottom: 2px'
				}
			}
		}
	},
	props: [
		'content'
	],
	components: {
		'module-anchor': ModuleAnchor,
		'module-figure': ModuleFigure,
		'module-svg': ModuleSVG,
		'module-video': ModuleVideo,
		'module-card': ModuleCard,
		'module-card-basic': ModuleCardBasic,
		'module-unordered-list': ModuleUnorderedList,
		'module-table': ModuleTable,
		'recap-items': RecapItems,
		'page-section-header': PageSectionHeader,
		'content-two-column-generic': ContentTwoColumnGeneric,
		'two-line-header': TwoLineHeader,
		'ref': Reference
	}
};

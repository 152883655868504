<template>
	<div class="module-box-wrapper space-y-2">
		<div @click="select" class="module-box" :class="{'canadian-module': canadian}" :style="cssVariables">
			<h3 class="text-3xl font-bold">
				{{ module.name }}
			</h3>
			<!-- Temporary -->
			<p class="text-lg font-bold">
				{{ module.title }}
			</p>
			<div class="icon-wrapper">
				<font-awesome-icon :icon="['fas', statusIcon]" transform="shrink-6" class="fa-3x" />
			</div>
			<div v-if="canadian" class="canada-text-wrapper space-y-2">
				<p>
					<font-awesome-icon :icon="['fab', 'canadian-maple-leaf']" transform="" class="" /> Canada only
				</p>
				<small class="text-xs">Unlocked with the purchase of any module</small>
			</div>
		</div>
		<div class="learning-objectives-wrapper">
			<a :href="learningObjectives" class="font-bold">Learning Objectives</a>
		</div>
	</div>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js';
import NProgress from 'nprogress';
import { defaultClient } from '../ensemble-js/client';
const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_KEY);

export default {
	name: 'ModuleBox',
	props: {
		productId: Number,
		module: Object,
		state: String,
		bgColor: String,
		textColor: String,
		iconWrapperColor: String,
		iconColor: String,
		canadian: Boolean
	},
	data: function() {
		return {
			clickable: true
		}
	},
	mounted: function() {},
	methods: {
		select: function() {
			if (this.state === 'purchased' || this.state === 'completed') {
				this.$router.push({ name: 'ModuleViewer', params: { module: this.module.path } });
			}
			else if (this.clickable) {
				this.startPurchase();
				NProgress.start();
			}
		},
		startPurchase: async function() {
			this.clickable = false;
			const stripe = await stripePromise;
			defaultClient.carts.createCart([this.productId]).then(x => {
				NProgress.done();
				stripe.redirectToCheckout({
				// Make the id field from the Checkout Session creation API response
				// available to this file, so you can provide it as parameter here
				// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
					sessionId: x.data.sessionId
				}).then(function (result) {
				// If `redirectToCheckout` fails due to a browser or network
				// error, display the localized error message to your customer
				// using `result.error.message`.
				});
			}).catch(error => {
				console.log(error);
			});
		}
	},
	computed: {
		cssVariables() {
			return {
				'--bg-color': this.bgColor,
				'--text-color': this.textColor,
				'--icon-wrapper-color': this.iconWrapperColor,
				'--icon-color': this.iconColor
			}
		},
		statusIcon() {
			let iconName = 'shopping-cart';
			if (this.state === 'purchased') {
				iconName = 'arrow-right';
			}
			else if (this.state === 'completed') {
				iconName = 'check';
			}
			return iconName;
		},
		learningObjectives() {
			return `learning-objectives/${this.module.path}`;
		}
	}
}
</script>
<style lang="postcss" scoped>
	.module-box-wrapper {
		@apply mb-8;
	}
	.module-box {
		width: 100%;
		min-height: 275px;
		position: relative;
		padding: 1rem;
		background-color: var(--bg-color);
		color: var(--text-color);
		transition: 300ms;
		&:hover {
			background-color: theme('colors.burnt-magenta');
			color: #fff;
			.icon-wrapper {
				background-color: #fff;
				> * {
					color: theme('colors.burnt-magenta');
				}
			}
		}
	}
	.icon-wrapper {
		position: absolute;
		right: 1rem;
		bottom: 2rem;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: theme('colors.dark-teal');
		cursor: pointer;
		> * {
			color: #fff;
		}
	}
	/* For Canada specific box */
	.module-box.canadian-module {
		color: #fff;
		.canada-text-wrapper {
			position: absolute;
			bottom: 2rem;
		}
		.learning-objectives-wrapper {
			display: none;
		}
		.icon-wrapper {
			background-color: theme('colors.solid-green');
		}
		&:hover {
			.icon-wrapper {
				background-color: #fff;
			}
		}
	}
	@screen md {
		.module-box {
			width: 100%;
			min-height: 300px;
		}
	}

	@screen lg {
		.module-box-wrapper {
			@apply mb-0;
		}
	}
</style>

<template>
	<div>
		<p class="header-accent" :class="cssClass">
			<span v-if="cssClass == 'dig-deeper'">Dig Deeper</span>
			<span v-else-if="cssClass == 'patients-ask'">Patients Ask</span>
			<font-awesome-icon :icon="['fas', 'arrow-right']" transform="rotate-45" class="fa-lg" />
		</p>
		<h2>
			<slot name="header-text"></slot>
		</h2>
	</div>
</template>

<script>
export default {
	name: 'TwoLineHeader',
	props: {
		designType: {
			type: String
		}
	},
	computed: {
		cssClass() {
			return this.designType;
		}
	}
}
</script>

<style lang="postcss" scoped>
	.header-accent {
		@apply text-base font-bold uppercase inline-block mb-3 p-2;
		max-width: 200px;
		background-color: var(--accent-background-color);
		span {
			@apply mr-4;
		}
	}

	/* Dig Deeper version */
	.header-accent.dig-deeper {
		--accent-background-color: theme('colors.solid-green');
	}
	/* Patients Ask version */
	.header-accent.patients-ask {
		--accent-background-color: theme('colors.burnt-magenta');
	}
</style>
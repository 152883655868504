<template>
	<div class="container mx-auto">
		<section class="grid grid-cols-1 md:grid-cols-10 px-8 py-20 gap-x-8 gap-y-8">
			<article class="col-span-1 md:col-span-6 space-y-6 font-thin">
				<section class="space-y-2">
					<h2 class="text-4xl text-burnt-magenta leading-tight">Accredited online learning</h2>
					<p>
						This activity is an Accredited Self-Assessment Program (Section 3) as defined by the Maintenance of Certification Program of the Royal College of Physicians and Surgeons of Canada, and approved by the University of Calgary Office of Continuing Medical Education and Professional Development. You may claim a maximum of <strong>9 hours</strong> (credits are automatically calculated).
					</p>
				</section>
				<section class="space-y-2 italic">
					<h2 class="text-2xl font-bold leading-tight">
						Pricing
					</h2>
					<p class="">
						You must register to access the online learning program. Modules may be purchased for $75 each, or as a program bundle of 9 modules for $300
					</p>
					<p class="">
						Please note that all modules must be completed in order to obtain MOC credits.
					</p>
				</section>
				<section class="space-y-2 italic">
					<h2 class="text-2xl font-bold leading-tight">
						Claiming your credits
					</h2>
					<p class="">
						Claiming your credits: Visit MAINPORT <a href="https://mainport.royalcollege.ca">https://mainport.royalcollege.ca</a> to record your learning and outcomes using SAP ID: 00013421.
					</p>
					<p class="">
						Through an agreement between the Royal College of Physicians and Surgeons of Canada and the American Medical Association, physicians may convert Royal College MOC credits to AMA PRA Category 1 Credits<sup>&trade;</sup>. Information on the process to convert Royal College MOC credit to AMA credit can be found at <a target="_blank" class="underline" href="https://edhub.ama-assn.org/pages/applications">https://edhub.ama-assn.org/pages/applications</a>
					</p>
				</section>
				<section>
					<p>
						<small>This program has received an educational grant or in-kind support from <a href="https://cannabis.shoppersdrugmart.ca/en_CA/health-practitioner-resources" target="_blank" class="underline">Medical Cannabis by Shoppers</a>&trade;</small>
					</p>
				</section>
			</article>
			<article class="col-span-1 md:col-span-4">
				<div class="grid grid-cols-1 space-y-4">
					<router-link :to='{ name: "Login" }' class="bg-solid-green block w-full py-4 text-white text-xl text-center">Log in</router-link>
					<router-link :to='{ name: "Register" }' class="bg-burnt-magenta block w-full py-4 text-white text-xl text-center">Register now</router-link>
				</div>
			</article>
		</section>
	</div>
</template>
<script>
export default {
	name: 'HomeContent',
	mounted: function() {
		if (this.isLoggedIn) {
			this.$router.push({ name: 'Overview' });
		}
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.loggedIn;
		}
	}
}
</script>
<style lang="postcss" scoped>
</style>

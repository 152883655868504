<template>
	<div class="bg-dark-teal text-white" id="about-program">
		<div class="container mx-auto">
			<section class="grid grid-cols-1 px-8 py-12">
				<article class="space-y-4">
					<h2 class="text-3xl mb-4">Our program</h2>
					<p>
						As a complement to our annual Cannabinoids in Clinical Practice conferences, the Canadian Consortium for the Investigation of Cannabinoids (CCIC) is proud to announce the launch of the Canadian Cannabis Syllabus (CCS)!
					</p>
					<p>
						The CCS provides the most relevant and comprehensive evidence-based information on cannabis and cannabinoids, spanning topics from the plant to policy in a 9-module online learning program. This initiative will provide foundational and practical knowledge that will help inform healthcare practitioners on how to guide patients using cannabis/cannabinoids as a treatment option.
					</p>
					<div>
						<p class="italic">
							Pricing and credits
						</p>
						<ul class="list-disc list-ouside pl-4 italic">
							<li>
								Modules may be purchased for $75 each, or as a program bundle of 9 modules for $300
							</li>
							<li> CCIC will not provide refunds for individual or bundled module purchases, except in special cases such as double payment. Questions or concerns relating to this policy can be sent to <a href="mailto:info@ccic.net">info@ccic.net</a> with the subject line “CCS Refund Policy”.
							</li>
							<li>
								In order to obtain MOC credits, all modules must be completed
							</li>
						</ul>
					</div>
					<p class="font-bold">
						Content for the CCS was developed by internationally recognized scientists and physicians covering the following topics:
					</p>
					<modules-static />
					<div>
						<p class="font-bold">
							After participating in this program, participants will be able to:
						</p>
						<ul class="list-disc list-ouside pl-4">
							<li>
								Discuss the safety and potential benefits of medical cannabis or cannabinoids with patients and family members
							</li>
							<li>
								Identify various pain and non-pain conditions for which medical cannabis is clinically beneficial in appropriate patients
							</li>
							<li>
								Learn how to safely authorize a patient to access medical cannabis in Canada
							</li>
						</ul>
					</div>
				</article>
			</section>
		</div>
	</div>
</template>
<script>
import ModulesStatic from '@/components/about/ModulesStatic.vue';

export default {
	name: 'AboutProgramContent',
	components: {
		'modules-static': ModulesStatic
	}
}
</script>

<template>
	<main>
		<full-width-bg-generic displayIcon heroText="Cannabis education for health care professionals" :backgroundType="0" :backgroundYPosition="25" />
		<div class="container mx-auto">
			<forgot-password />
		</div>
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'

export default {
	name: 'ForgotPassword',
	components: {
		'forgot-password': ForgotPassword,
		'full-width-bg-generic': FullWidthBackgroundGeneric
	}
}
</script>

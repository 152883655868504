export default class Resource {
    constructor(axios) {
        this.axios = axios;
    }

    getHeader(useToken) {
		var result = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};

        if (useToken) {
            const token = localStorage.getItem(process.env.VUE_APP_TOKEN_STORAGE_NAME);
			result.Authorization = 'Bearer ' + token;
		}
        return result;
    }

    async httpGet(path, useToken) {
        const response = await this.axios.get(path, {
            dataType: 'json',
            headers: this.getHeader(useToken)
        });
        return response;
    }

    async httpPost(path, data, useToken) {
        const response = await this.axios.post(path, data, {
			headers: this.getHeader(useToken)
        });
        return response;
    }

    async httpPut(path, data, useToken) {
        const response = await this.axios.put(path, data, {
			headers: this.getHeader(useToken)
        });
        return response;
    }

	async httpDownload(path, filename, useToken) {
		this.axios.get(path, {
			headers: this.getHeader(useToken),
			responseType: 'blob'
		}).then(response => {
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);

			fileLink.click();
		});
	}
}

<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="blefoll">
		<template #faculty-title>
			BERNARD LEFOLL, MD, PhD, MCFP, AM
		</template>
		<template #faculty-details>
			<p>Professor of Family and Community Medicine, Pharmacology, Psychiatry and Institute of Medical Sciences, University of Toronto</p>
			<p>Head, Translational Addiction Research Laboratory</p>
			<p>Centre for Addiction and Mental Health</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'BLefollCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div class="download-certificate-wrapper">
		<p class="text-2xl text-white">
			Your certificate should download automatically. If it doesn't, <a href="#" @click.prevent="handleClick" class="font-bold">please click here</a>.
		</p>
	</div>
</template>

<script>
export default {
	name: 'DownloadCertificate',
	props: {
		handleClick: Function
	}
}
</script>

<style lang="postcss" scoped>
	.download-certificate-wrapper {
		@apply w-full bg-burnt-magenta;
		height: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
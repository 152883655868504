<template>
	<main class="container mx-auto flex justify-center items-center">
		<h2 class="text-3xl text-solid-green">Thank you for your purchase.</h2>
	</main>
</template>

<script>
export default {
	name: 'PurchaseSuccess'
}
</script>

<style lang="postcss" scoped></style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import AccountSettings from '../views/AccountSettings.vue'
import AboutProgram from '../views/AboutProgram.vue'
import FacultyMember from '../views/FacultyMember.vue'
import AskTheFaculty from '../views/AskTheFaculty.vue'
import ProgramOverview from '../views/ProgramOverview.vue'
import Purchase from '../components/Purchase.vue'
import PurchaseSuccess from '../views/PurchaseSuccess.vue'
import PurchaseCancelled from '../views/PurchaseCancelled.vue'
import Quiz from '../views/Quiz.vue'
import QuizResults from '../views/QuizResults.vue'
import ModuleViewer from '../components/ModuleViewer.vue'
import NotFound from '../views/NotFound.vue'
import EmailVerify from '../views/EmailVerify.vue'
import LearningObjectives from '../views/LearningObjectives.vue'
import { defaultClient } from "../ensemble-js/client";

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		name: 'NotFound',
		component: NotFound
	},
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: ForgotPassword
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/account-settings',
		name: 'AccountSettings',
		component: AccountSettings
	},
	{
		path: '/about-program',
		name: 'AboutProgram',
		component: AboutProgram
	},
	{
		path: '/faculty/:member',
		name: 'FacultyMember',
		component: FacultyMember
	},
	{
		path: '/ask-the-faculty',
		name: 'AskTheFaculty',
		component: AskTheFaculty
	},
	{
		path: '/overview',
		name: 'Overview',
		component: ProgramOverview
	},
	{
		path: '/learning-objectives/:module',
		name: 'LearningObjectives',
		component: LearningObjectives
	},
	{
		path: '/modules/:module/quiz',
		name: 'Quiz',
		component: Quiz
	},
	{
		path: '/:module/sessions/:sessionId/results',
		name: 'QuizResults',
		component: QuizResults
	},
	{
		path: '/modules/:module/:section',
		name: 'ModuleViewer',
		component: ModuleViewer
	},
	{
		path: '/modules/:module/:section/:refNumber',
		name: 'References',
		component: ModuleViewer
	},
	{
		path: '/modules/:module',
		name: 'Module',
		component: ModuleViewer
	},
	{
		path: '/purchase',
		name: 'Purchase',
		component: Purchase
	},
	{
		path: '/purchase/success',
		name: 'PurchaseSuccess',
		component: PurchaseSuccess
	},
	{
		path: '/purchase/cancelled',
		name: 'PurchaseCancelled',
		component: PurchaseCancelled
	},
	{
		path: '/verify-email/:email/:token',
		name: 'EmailVerify',
		component: EmailVerify
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	// if module content is requested
	if (to.params.module && to.name !== from.name) {
		// laod the manifest and check if the productId has been purchased
		import('@/components/modules/manifest.js').then(m => {
			const module = m.default.modules.find(m => m.path === to.params.module);
			if (module) {
				defaultClient.users.purchases().then(x => {
					const purchase = x.data.find(p => p.productId === module.productId);
					if (purchase) {
						next();
					}
					else {
						router.push({ name: 'Overview' });
					}
				});
			}
		});
	}
	next();
});

export default router

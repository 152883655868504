import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'

// Import Solid FA icons
import { faArrowDown, faShoppingCart, faCheck, faArrowLeft, faArrowRight, faAngleRight, faBars, faTimes, faListUl, faLock, faMale, faChartBar, faRandom, faBullseye } from '@fortawesome/free-solid-svg-icons'

// Import Brand FA icons
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'

// Import NProgress CSS
import "nprogress/nprogress.css"

// Add to icon library
library.add(faArrowDown, faShoppingCart, faCheck, faArrowLeft, faArrowRight, faAngleRight, faBars, faTimes, faListUl, faLock, faCanadianMapleLeaf, faMale, faChartBar, faRandom, faBullseye)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

// Use Vuelidate library
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
    <section class="module-content grid grid-cols-1 p-4 lg:p-0" :class="cssClass">
        <div class="container mx-auto flex justify-center items-center py-8 lg:py-10">
            <header class="module-section-header">
                <slot name="header"></slot>
            </header>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PageSectionHeader',
    props: {
        layoutStyle: {
            type: String
        }
    },
    computed: {
        cssClass() {
            return this.layoutStyle || 'default';
        }
    }
}
</script>

<style lang="postcss" scoped>
   .module-content {
       color: var(--content-text-color);
       background-color: var(--content-background-color);
       .module-section-header {
            @apply text-5xl;
            @apply leading-tight;
            color: var(--content-header-color);
       }
   }
    /* Default Section Header theme */
    .module-content.default {
        --content-background-color: theme('colors.cool-gray');
        --content-header-color: theme('colors.light-teal');
    }
</style>
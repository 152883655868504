<template>
	<main>
		<download-certificate v-if="downloadCertificate" :handleClick="generateCert" />
		<full-width-bg-generic displayIcon heroText="Ask the faculty" :backgroundType="3" />
		<ask-the-faculty class="container mx-auto" />
	</main>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";
import DownloadCertificate from "@/components/DownloadCertificate.vue";
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue';
import AskTheFaculty from '@/components/AskTheFaculty.vue';

export default {
	name: 'AskTheFaculty',
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric,
		'ask-the-faculty': AskTheFaculty,
		'download-certificate': DownloadCertificate
	},
	mounted: function() {
		if (this.downloadCertificate) {
			this.generateCert();
		}
	},
	methods: {
		generateCert: function() {
			defaultClient.documents.genCertificate();
		}
	},
	computed: {
		downloadCertificate() {
			return this.$route.params.downloadCertificate;
		}
	}
}
</script>
<style lang="postcss" scoped>
</style>

<template>
	<page-header-single-column>
		<template #header>
			<h2>References</h2>
		</template>
		<template #content>
			<ol class="list-decimal list-outside ml-6 space-y-1 text-sm">
				<li v-for="(item, index) in content.references" :key="item.id" :class="index + 1 == refNumber ? 'reference-item--highlighted' : null" class="reference-item">
					<p v-if="item.refLink">
						{{ item.ref }} <a v-if="item.refLink" :href="item.refLink" target="_blank" class="underline">{{ item.refLink }}</a>.
					</p>
					<p v-else v-html="item.ref"></p>
				</li>
			</ol>
		</template>
	</page-header-single-column>
</template>

<script>
	import ModulePage from '../module-page.js';
	import PageHeaderSingleColumn from './layouts/PageHeaderSingleColumn.vue';

	export default {
		name: 'ReferenceList',
		props: {
			'content': {
				type: Object
			}
		},
		extends: ModulePage,
		components: {
			'page-header-single-column': PageHeaderSingleColumn
		},
		data() {},
		computed: {
			refNumber() {
				return this.$route.params.refNumber;
			}
		}
	}
</script>

<style lang="postcss" scoped>
</style>

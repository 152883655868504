<template>
	<main>
		<full-width-bg-generic :backgroundType="0" />
		<div class="container mx-auto">
			<reset-password-form />
		</div>
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue'
import ResetPassword from '../components/ResetPassword.vue'

export default {
	name: 'ResetPassword',
	components: {
		'reset-password-form': ResetPassword,
		'full-width-bg-generic': FullWidthBackgroundGeneric
	}
}
</script>
<style lang="postcss" scoped>
</style>
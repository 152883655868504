<template>
	<div class="overlay-wrapper" @click="closeSideNav">
	</div>
</template>
<script>
import { EventBus } from '@/ensemble-js/event-bus.js'

export default {
	name: 'Overlay',
	methods: {
		closeSideNav() {
			EventBus.$emit('closeSideNav');
			EventBus.$emit('toggleOverlay');
		}
	}
}
</script>
<style lang="postcss" scoped>
	.overlay-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: transparent;
		z-index: 15;
	}
</style>
<template>
	<div id="about-ccic">
		<div class="container mx-auto">
			<section class="grid grid-cols-1 px-8 py-12">
				<article id="about-ccic">
					<h2 class="text-3xl text-burnt-magenta mb-4">About CCIC</h2>
					<p>
						The Canadian Consortium for the Investigation of Cannabinoids (CCIC) is a federally registered non-profit organization whose vision is to be the trusted resource for evidence-based education on the health impacts of cannabis and serve as a national hub for ongoing cannabis/cannabinoid research. We advance and promote evidence-based research and education concerning the endocannabinoid system, therapeutic applications of cannabinoids, potential harms associated with cannabis use and the societal and health impacts of non-medical cannabis use to the health care community and the general public. The CCIC represents a group of researchers, healthcare practitioners and educators who are committed to providing access to balanced and unbiased information regarding what the current science says about the biological, health and societal effects of cannabis and cannabinoids.
					</p>
				</article>
			</section>
		</div>
	</div>
</template>
<script>

export default {
	name: 'AboutCCICContent',
	components: {}
}
</script>

<style lang="postcss" scoped>
</style>

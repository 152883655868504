<template>
	<main class="module-content flex-grow py-8" :class="cssClass">
		<div class="container mx-auto">
			<header class="module-content-header">
				<slot name="header"></slot>
			</header>
			<div class="module-content-body">
				<slot name="content"></slot>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'PageHeaderSingleColumn',
	props: {
		layoutStyle: {
			type: String,
			default: 'default'
		}
	},
	computed: {
		cssClass() {
			return this.layoutStyle;
		}
	}
}
</script>

<style lang="postcss">
	/* Global CSS across all module pages */

	/* General styles */
	.module-content {
		@apply px-4;
		color: var(--content-text-color);
		background-color: var(--content-background-color);
	}

	.module-content-header {
		@apply mb-6 text-2xl leading-tight;
		color: var(--content-header-color);

		/* Subsection styles */
		.subsection-header {
			color: var(--content-sub-section-header);
		}

		 /* Header Superscript */
		.superscript {
			font-size: 0.65em;
		}
	}

	.module-content-body {
		> *:not(:last-child) {
			@apply mb-4;
		}
		> *:last-child {
			@apply mb-6;
		}

		/* Header tags found in module pages */
		h3, h4 {
			@apply font-bold;
		}

		h3 {
			@apply text-lg;
			color: var(--content-sub-header-color);
		}

		h3.subheader {
			color: inherit;
		}

		h3.subheader--large {
			@apply text-2xl;
		}

		/* Labels for lists */
		.list-title {
			&--tight {
				@apply mb-0;
			}
		}

		/* Highlighted text content */
		.highlight {
			color: theme('colors.solid-green');
			font-weight: bold;
		}

		/* Citation text for module figures */
		.figure-cite-text {
			@apply text-xs italic;
		}

		/* Reference page styles */
		.reference-item {
			&--highlighted {
				@apply bg-solid-green;
				p {
					color: #fff;
				}
			}
		}
		.reference-anchor {
			color: theme('colors.solid-green');
			text-decoration: underline;
		}
	}

	/* Default theme */
	.module-content.default {
		--content-text-color: theme('colors.cool-gray');
		--content-background-color: #fff;
		--content-header-color: theme('colors.burnt-magenta');
		--content-sub-header-color: theme('colors.solid-green');
		--content-sub-section-header: theme('colors.cool-gray');
	}
	/* Dig Deeper theme */
	.module-content.dig-deeper {
		--content-text-color: #fff;
		--content-background-color: theme('colors.dark-teal');
		--content-header-color: #fff;
		--content-sub-header-color: theme('colors.solid-green');
	}
	/* Patients Ask theme */
	.module-content.patients-ask {
		--content-text-color: #fff;
		--content-background-color: theme('colors.cool-gray');
		--content-header-color: #fff;
		--content-sub-header-color: theme('colors.solid-green');
		h3 {
			font-style: italic;
		}
	}
	/* Recap theme */
	.module-content.recap {
		--content-text-color: theme('colors.cool-gray');
		--content-background-color: #fff;
		--content-header-color: theme('colors.solid-green');
	}

	/* Media Queries */
	@screen md {
		.module-content {
			.module-content-header {
				@apply text-4xl;
			}
		}
	}

	@screen lg {
		.module-content {
			@apply px-0;
		}
	}
</style>

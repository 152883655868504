<template>
	<header class="w-full sticky top-0 z-10 main-navigation-wrapper" :class="navWrapperBg">
		<nav class="main-navigation lg:container mx-auto px-4" role="navigation">
			<div class="nav-logo-wrapper">
				<h1 @click="goHome">
					<img :src="require(`@/assets/images/${logoFile}.svg`)" alt="CCIC Logo" width="250" height="auto" />
				</h1>
			</div>
			<div class="mobile-nav-toggle-wrapper lg:hidden">
				<font-awesome-icon v-if="showMobileNav === false" :icon="['fas', 'bars']" title="Hamburger Toggle" class="fa-2x fill-current" @click="toggleMobileNav" />
				<font-awesome-icon v-else :icon="['fas', 'times']" title="Hamburger Toggle" class="fa-2x fill-current" @click="toggleMobileNav" />
			</div>
			<div class="nav-menu-wrapper -mx-4" :class="showMobileNav ? 'flex' : 'hidden'">
				<!-- Disabled Temporarily -->
				<!-- <div v-if="!isLoggedIn" class="nav-item">
					<router-link :to='{ name: "Home" }'>Home</router-link>
				</div> -->
				<div class="nav-item">
					<router-link :to='{ name: "AboutProgram"}'>About our program</router-link>
				</div>
				<div v-if="!isLoggedIn" class="nav-item">
					<router-link :to='{ name: "Login" }'>Log in</router-link>
				</div>
				<div v-if="!isLoggedIn" class="nav-item">
					<router-link :to='{ name: "Register" }'>Register now</router-link>
				</div>
				<div v-if="isLoggedIn" class="nav-item">
					<router-link :to='{ name: "AskTheFaculty" }'>Ask the faculty</router-link>
				</div>
				<div v-if="isLoggedIn" class="nav-item">
					<router-link :to='{ name: "Overview" }'>Program overview</router-link>
				</div>
				<div v-if="isLoggedIn" class="nav-item">
					<router-link :to='{ name: "AccountSettings" }'>Account settings</router-link>
				</div>
				<div v-if="isLoggedIn" class="nav-item">
					<span @click="logOut">Sign out</span>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
import { defaultClient } from "../ensemble-js/client";

export default {
	name: 'MainNavigation',
	data: function() {
		return {
			scrolled: false,
			showMobileNav: false
		}
	},
	methods: {
		logOut() {
			defaultClient.users.logout();
			this.$store.commit('setLoggedOut');
			this.$router.push({ name: 'Home' });
		},
		updateScrollPos() {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 0) {
				this.scrolled = true;
			}
			else if (scrollPosition === 0) {
				this.scrolled = false;
			}
		},
		goHome() {
			this.$router.push({
				name: "Home"
			})
		},
		toggleMobileNav() {
			this.showMobileNav = !this.showMobileNav;
		}
	},
	mounted() {
		window.addEventListener('scroll', this.updateScrollPos);
	},
	computed: {
		isLoggedIn() {
			return this.$store.state.loggedIn;
		},
		navWrapperBg() {
			return this.scrolled ? 'bg-solid-green' : 'bg-white';
		},
		logoFile() {
			return this.scrolled ? 'ccic-logo-white' : 'ccic-logo-color';
		}
	}
}
</script>

<style lang="postcss" scoped>
	.main-navigation {
		height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	.main-navigation .nav-logo-wrapper {
		padding: 1.5rem 0;
		cursor: pointer;
	}
	.main-navigation .nav-menu-wrapper {
		width: 100vw;
		flex-direction: column;
		z-index: 30;
		background-color: theme('colors.solid-green');
	}
	.main-navigation .nav-item {
		display: flex;
		align-items: flex-end;
		position: relative;
		padding: 1.5rem 1.75rem;
		color: theme('colors.cool-gray');
		&:hover {
			color: #fff;
			background-color: theme('colors.solid-green');
		}
	}
	.main-navigation .nav-item a, span {
		color: inherit;
		text-decoration: none;
		text-align: center;
		cursor: pointer;
	}
	@screen lg {
		.main-navigation {
			align-items: stretch;
			flex-wrap: nowrap;
		}
		.main-navigation .nav-menu-wrapper {
			width: auto;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			background-color: transparent;
		}
	}
</style>

<template>
	<main>
		<full-width-bg-generic displayIcon heroText="Cannabis education for health care professionals" :backgroundType="0" :backgroundYPosition="25" />
		<home-content />
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue'
import HomeContent from '@/components/HomeContent.vue'

export default {
	name: 'Home',
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric,
		'home-content': HomeContent
	}
}
</script>

<template>
	<img :src="imgSrc(module, figure)" :alt="altText" :title="altText" :width="svgWidth" :height="svgWidth"/>
</template>

<script>
export default {
	name: 'ModuleSVG',
	props: {
		module: String,
		figure: String,
		svgWidth: String,
		svgHeight: {
			type: String,
			default: 'auto'
		},
		altText: {
			type: String,
			default: 'Icon'
		}
	},
	methods: {
		imgSrc: function(mod, fig) {
			return `${this.staticBase}/module-${mod}/Module%20${mod}%20Figure%20${fig}/Module%20${mod}%20Figure%20${fig}.svg`;
		}
	},
	computed: {
		staticBase() {
			return process.env.VUE_APP_STATIC_BASE_URL;
		}
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<div>
		<div class="container mx-auto">
			<section class="grid grid-cols-1 px-8 pt-12 md:pt-20">
				 <ul class="about-page-nav">
					<li><a href="#about-ccic">About CCIC</a></li>
					<li><a href="#about-program">Our program</a></li>
					<li><a href="#about-faculty">Our faculty</a></li>
				</ul>
			</section>
		</div>
	</div>
</template>
<script>
export default {
	name: 'AboutProgramNav'
}
</script>

<style lang="postcss" scoped>
	.about-page-nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		> li {
			width: 100%;
			padding: 1rem;
			background-color: theme('colors.dark-teal');
			color: #fff;
			text-align: center;
			font-size: 1.25rem;
			font-weight: 600;
		}
	}
	@screen md {
		.about-page-nav {
			> li {
				width: 27%;
			}
		}
	}
</style>
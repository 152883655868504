<template>
	<sup v-if="references != null" class="superscript">
		<router-link :to="{ name: 'References', params: { section: 'references', refNumber: ref }}" v-for="(ref, index) in references" :key="index" target="_blank">
			{{ ref }}<span v-if="index < references.length - 1">,</span>
		</router-link>
	</sup>
</template>
<script>
export default {
	name: "Reference",
	props: [
		"ids",
		"content"
	],
	data: function() {
		return {
			references: null
		};
	},
	mounted: function() {
		const references = [];
		this.ids.forEach(function(refId) {
			const idx = this.content.references.findIndex(x => x.id === refId);
			const refNumber = idx + 1;
			references.push(refNumber);
		}, this);

		this.references = references;
	}
}
</script>

<style lang="postcss" scoped>
	/* Using reset for <sup> tags found here https://css-tricks.com/snippets/css/prevent-superscripts-and-subscripts-from-affecting-line-height/ */
	sup {
		vertical-align: baseline;
		position: relative;
		top: -0.4em;
	}
	.superscript {
		font-size: 0.75em;
		margin-left: -0.15rem;
	}
</style>

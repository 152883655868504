<template>
	<img :src="imgSrc(module, figure)" :srcset="imgSrcSet(module, figure)" :alt="defaultAltText" :title="defaultAltText" :style="cssVariables" />
</template>

<script>
export default {
	name: 'ModuleFigure',
	props: {
		module: String,
		figure: String,
		imageDesktopWidth: String,
		imageDesktopHeight: String,
		altText: {
			type: String,
			default: null
		}
	},
	methods: {
		imgSrc: function(mod, fig, dpi) {
			let dpiStr = "";
			let dpiDir = "1x";
			if (dpi != null) {
				dpiStr = `@${dpi}x`;
				dpiDir = `${dpi}x`;
			}
			return `${this.staticBase}/module-${mod}/Module%20${mod}%20Figure%20${fig}/${dpiDir}/Module%20${mod}%20Figure%20${fig}${dpiStr}.png`;
		},
		imgSrcSet: function(mod, fig) {
			const result = `${this.imgSrc(mod, fig, 2)} 2x, ${this.imgSrc(mod, fig, 4)} 4x`;
			return result;
		}
	},
	computed: {
		staticBase() {
			return process.env.VUE_APP_STATIC_BASE_URL;
		},
		defaultAltText() {
			return this.altText == null ? `Module ${this.module} Figure ${this.figure}` : this.altText;
		},
		cssVariables() {
			return {
				'--image-width': this.imageDesktopWidth ? `${this.this.imageDesktopWidth}px` : `100%`,
				'--image-height': this.imageDesktopHeight ? `${this.imageDesktopHeight}px` : 'auto'
			}
		}
	}
}
</script>

<style lang="postcss" scoped>
	img {
		max-width: 100%;
		height: auto;
	}
	@screen lg {
		img {
			max-width: var(--image-width);
			height: var(--image-height);
		}
	}
</style>

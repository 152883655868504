<template>
	<main class="program-overview">
		<full-width-bg-generic displayIcon heroText="Learn at your own pace" :backgroundType="1" />
		<two-col-banner backgroundColor="#5c7f71" textColor="#fff" class="py-6" v-if="certificateAvailable && !showPostSurvey">
			<template #col-left>
				<h2 class="text-2xl">
					Congratulations on completing the Canadian Cannabis Syllabus!
				</h2>
			</template>
			<template #col-right>
				<router-link :to='{ name: "AskTheFaculty", params: { downloadCertificate: true } }' class="block w-full py-4 bg-white text-dark-teal text-center text-2xl">View your certificate</router-link>
			</template>
		</two-col-banner>
		<two-col-banner v-else-if="certificateAvailable" backgroundColor="#5c7f71" textColor="#fff" class="py-6">
			<template #col-left>
				<h2 class="text-2xl">
					Please complete our post-program survey to download your certificate.
				</h2>
			</template>
			<template #col-right>
				<a href="#" @click.prevent="goToSurvey" class="block w-full py-4 bg-white text-dark-teal text-center text-2xl">Go To Survey</a>
			</template>
		</two-col-banner>
        <div class="container mx-auto">
			<section class="grid grid-cols-1 gap-y-4 md:gap-y-4 md:grid-cols-3 px-8 pt-12 md:pt-20 pb-12 font-thin">
				<article class="col-span-3 md:col-span-2">
					<h2 class="text-2xl">Don't forget, in order to receive your certificate, you must participate in the Q&A.</h2>
				</article>
				<article class="col-span-3 md:col-span-1">
					<router-link :to="{ name: 'AskTheFaculty'}" class="block w-full px-2 py-4 bg-solid-green font-bold text-white text-xl text-center">Ask the faculty</router-link>
				</article>
				<article class="col-span-3 space-y-2">
					<h3 class="text-3xl text-burnt-magenta">
						Learning Objectives
					</h3>
					<p class="font-bold">
						After completing this Canadian Cannabis Syllabus, participants will be able to:
					</p>
					<ul class="list-disc list-outside pl-4 space-y-1">
						<li>
							Discuss the safety and potential benefits of medical cannabis or cannabinoids with patients and family members
						</li>
						<li>
							Identify various pain and non-pain conditions for which medical cannabis is clinically beneficial in appropriate patients
						</li>
						<li>
							Learn how to safely authorize a patient to access medical cannabis in Canada
						</li>
					</ul>
				</article>
				<article class="col-span-3 space-y-2" v-if="showBundleInfo">
					<h3 class="text-3xl text-burnt-magenta">
						Pricing and credits
					</h3>
					<p>
						Modules may be purchased for $75 each, or as a program bundle of 9 modules for $300.
					</p>
					<p>
						<strong class="font-bold">In order to obtain MOC credits</strong>, all modules must be successfully completed.
					</p>
				</article>
				<button v-if="showBundleInfo" @click="purchaseBundle" class="col-span-3 py-4 bg-burnt-magenta text-white text-xl font-bold text-center">Purchase the program bundle</button>
			</section>
			<modules v-on:modules-ready="showModules" />
			<section class="grid grid-cols-1 px-8 py-12 font-thin">
				<article class="space-y-4 italic">
					<p>
						This activity is an Accredited Self-Assessment Program (Section 3) as defined by the Maintenance of Certification Program of the Royal College of Physicians and Surgeons of Canada, and approved by the University of Calgary Office of Continuing Medical Education and Professional Development. You may claim a maximum of <strong>9 hours</strong> (credits are automatically calculated).
					</p>
					<p>
						<strong>Claiming your credits:</strong> Visit MAINPORT <a href="https://mainport.royalcollege.ca" target="_blank" class="underline">https://mainport.royalcollege.ca</a> to record your learning and outcomes using SAP ID: 00013421.
					</p>
					<p>
						Through an agreement between the Royal College of Physicians and Surgeons of Canada and the American Medical Association, physicians may convert Royal College MOC credits to AMA PRA Category 1 Credits<sup>&trade;</sup>. Information on the process to convert Royal College MOC credit to AMA credit can be found at <a target="_blank" class="underline" href="https://edhub.ama-assn.org/pages/applications">https://edhub.ama-assn.org/pages/applications</a>
					</p>
					<p>
						CCIC will not provide refunds for individual or bundled module purchases, except in special cases such as double payment. Questions or concerns relating to this policy can be sent to info@ccic.net with the subject line “CCS Refund Policy”. If a dispute (a “Dispute”) arises out of or in connection with any provisions of this policy, you agree to work with CCIC to use reasonable endeavours to resolve the Dispute in an amicable, consultative, and co-operative manner.
					</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue';
import Banner from '@/components/FullWidthTwoColumnBanner';
import Modules from '@/components/Modules.vue';
import { defaultClient } from "../ensemble-js/client";
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_KEY);

export default {
	name: 'ProgramOverview',
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric,
		'two-col-banner': Banner,
		'modules': Modules
	},
	data() {
		return {
			loading: true,
			showPostSurvey: true,
			requiredQuizzes: {
				CA: [1, 3, 5, 7, 9, 11, 13, 15, 17],
				Intl: [1, 3, 5, 7, 9, 11, 13, 15]
			},
			certificateAvailable: false,
			bundleId: 10,
			showBundleInfo: false
		}
	},
	methods: {
		showModules: function(moduleSummary) {
			this.loading = false;
			this.certificateAvailable = moduleSummary.completedAll;
			this.showBundleInfo = moduleSummary.showBundle;
		},
		purchaseBundle: async function() {
			const stripe = await stripePromise;
			defaultClient.carts.createCart([this.bundleId]).then(x => {
				stripe.redirectToCheckout({
				// Make the id field from the Checkout Session creation API response
				// available to this file, so you can provide it as parameter here
				// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
					sessionId: x.data.sessionId
				}).then(function (result) {
				// If `redirectToCheckout` fails due to a browser or network
				// error, display the localized error message to your customer
				// using `result.error.message`.
				});
			}).catch(error => {
				console.log(error);
			});
		},
		goToSurvey() {
			this.showPostSurvey = false;
			window.open("https://www.surveymonkey.com/r/ccs-ccic", "_blank");
		}
	}
}
</script>
<style lang="postcss" scoped>
</style>

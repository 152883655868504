<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="mhill">
		<template #faculty-title>
			MATTHEW HILL, PhD
		</template>
		<template #faculty-details>
			<p>Professor of Cell Biology and Anatomy & Psychiatry, University of Calgary</p>
			<p>Executive Director, Canadian Consortium for the Investigation of Cannabinoids</p>
			<p>Chair, Gordon Research Conferences on Cannabinoids in the CNS</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'FClementCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

<template>
	<main>
		<full-width-bg-generic displayIcon heroText="Meet our team of experts" :backgroundType="2" />
		<page-nav />
		<about-ccic-content />
		<about-program-content />
		<about-faculty-content />
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue';
import AboutPageNav from '@/components/about/Nav.vue';
import AboutCCICContent from '@/components/about/CCIC.vue';
import AboutProgramContent from '@/components/about/Program.vue';
import AboutFacultyContent from '@/components/about/Faculty.vue';

export default {
	name: 'AboutProgram',
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric,
		'page-nav': AboutPageNav,
		'about-ccic-content': AboutCCICContent,
		'about-program-content': AboutProgramContent,
		'about-faculty-content': AboutFacultyContent
	}
}
</script>

<style lang="postcss" scoped>
	/* Adds margin-top to the top of the scrolled section */
	/* The margin-top value is equal to the height of the main nav bar */
	*[id|="about"] {
		scroll-margin-top: 100px;
	}
</style>

<template>
	<main>
		<full-width-bg-generic displayIcon heroText="Cannabis education for health care professionals" :backgroundType="0" :backgroundYPosition="25" />
		<div class="container mx-auto">
			<login-form />
		</div>
	</main>
</template>

<script>
// @ is an alias to /src
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue'
import Login from '@/components/Login.vue'

export default {
	name: 'LoginPage',
	components: {
		'full-width-bg-generic': FullWidthBackgroundGeneric,
		'login-form': Login
	}
}
</script>

<template>
	<video :controls="controls" :width="videoWidth" :height="videoHeight">
		<source :src="vidSrc(name, module, figure)" type="video/mp4" />
		Please use a browser that supports the video element.
	</video>
</template>

<script>
export default {
	name: 'ModuleVideo',
	props: {
		name: String,
		module: String,
		figure: String,
		videoWidth: String,
		videoHeight: {
			type: String,
			default: 'auto'
		},
		controls: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		vidSrc: function(name, mod, fig) {
			return (name != null)
				 ? `${this.staticBase}/module-${mod}/${name}/${name}.mp4`
				 : `${this.staticBase}/module-${mod}/Module%20${mod}%20Figure%20${fig}/Module%20${mod}%20Figure%20${fig}.mp4`;
		}
	},
	computed: {
		staticBase() {
			return process.env.VUE_APP_STATIC_BASE_URL;
		}
	}
}
</script>

<style lang="postcss" scoped>
</style>

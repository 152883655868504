<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="lbalneaves">
		<template #faculty-title>
			LYNDA BALNEAVES, RN, PhD
		</template>
		<template #faculty-details>
			<p>Associate Professor, College of Nursing, University of Manitoba</p>
			<p>Deputy Director, Canadian Consortium for the Investigation of Cannabinoids</p>
			<p>Past-President, Society for Integrative Oncology</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'LBalneavesCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

import Resource from './resource.js';

export default class Poll extends Resource {
    async getQuestions(questionGroup) {
		const url = (questionGroup != null)
			  ? 'polls/questions/' + questionGroup
			  : 'polls/question';
        return this.httpGet(url, true);
    }

    async getResponses(questionId) {
        return this.httpGet('polls/questions/' + questionId + '/responses', true);
    }

    async postAnswer(answer, userComment) {
        console.log(userComment);
        return this.httpPost('polls/answers/' + answer, { comment: userComment }, true);
    }

    async postAnswers(answers) {
        return this.httpPost('polls/answers', answers, true);
    }
}

<template>
	<main>
		<full-width-bg-generic displayIcon :backgroundType="1" />
		<account-settings class="container mx-auto" />
	</main>
</template>

<script>
import FullWidthBackgroundGeneric from '@/components/FullWidthBackgroundGeneric.vue';
import AccountSettings from '../components/AccountSettings';

export default {
	name: 'AccountSettings',
	components: {
		'account-settings': AccountSettings,
		'full-width-bg-generic': FullWidthBackgroundGeneric
	}
}
</script>
<style lang="postcss" scoped>
</style>

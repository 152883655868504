export default
{
	colors: ['#edf0df', '#dee3c7', '#d5dcb7', '#cbd3a6', '#c3cd97', '#b9c488', '#b1bc78', '#9eb356', '#5c7f71'],
	modules: [
		{
			productId: 1,
			quizId: 1,
			name: 'Module 1',
			title: 'Introduction to the Endocannabinoid (eCB) System',
			path: 'intro-to-ecs'
		},
		{
			productId: 2,
			quizId: 3,
			name: 'Module 2',
			title: 'Cannabis Basics',
			path: 'cannabis-basics'
		},
		{
			productId: 3,
			quizId: 5,
			name: 'Module 3',
			title: 'Long-term Health Issues Associated with Cannabis',
			path: 'long-term-health-cannabis'
		},
		{
			productId: 4,
			quizId: 7,
			name: 'Module 4a',
			title: 'Clinical Use of Cannabis and Cannabinoids in Pain',
			path: 'clinical-use-cannabis-pain'
		},
		{
			productId: 5,
			quizId: 9,
			name: 'Module 4b',
			title: 'Clinical Use of Cannabis in Non-Pain Conditions',
			path: 'clinical-use-therapeutic-applications'
		},
		{
			productId: 6,
			quizId: 11,
			name: 'Module 4c',
			title: 'Considerations When Authorizing Cannabis',
			path: 'considerations-authorizing-cannabis'
		},
		{
			productId: 7,
			quizId: 13,
			name: 'Module 5',
			title: 'Acute Adverse Effects, Drug Interactions and Contraindications of Cannabis',
			path: 'cannabis-effects-interactions'
		},
		{
			productId: 8,
			quizId: 15,
			name: 'Module 6',
			title: 'Cannabis Use Disorder',
			path: 'cannabis-use-disorder'
		},
		{
			productId: 9,
			quizId: 17,
			name: 'Module 7',
			title: 'Cannabis Policy in Canada',
			path: 'cannabis-policy-canada',
			canadian: true
		}
	]
}

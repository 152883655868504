import Resource from './resource.js';

export default class Videos extends Resource {
	async getVideos() {
		return this.httpGet('videos', false);
	}

	async getVideosById(id) {
		return this.httpGet('videos/' + id, false);
	}
}

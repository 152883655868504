<template>
	<main>
		<div class="container mx-auto">
			<quiz-question v-if="quizQuestion != null" :question="quizQuestion" @selectionChanged="selectionChanged" ref="questionDisplay" />
			<section class="grid grid-cols-1 px-4 mb-8">
				<button v-if="goToNext" v-on:click="continueToNext" class="quiz-button">Continue</button>
				<button v-else-if="!goToNext && selectedAnswer != null" v-on:click="submitAnswer" class="quiz-button">Submit</button>
			</section>
		</div>
	</main>
</template>

<script>
import QuizQuestion from '@/components/QuizQuestion.vue'
const wsClient = require("../ensemble-js/client.js");

export default {
	name: 'QuizPage',
	components: {
		'quiz-question': QuizQuestion
	},
	data: function() {
		return {
			quizQuestion: null,
			goToNext: false,
			nextQuestion: null,
			selectedAnswer: null,
			content: null
		}
	},
	mounted: function() {
		this.contentLoad.then(content => {
			this.content = content.default;

			wsClient.defaultClient.quizzes.startQuiz(this.content.quiz.id).then(response => {
				this.sessionId = response.data.sessionId;
				this.quizQuestion = response.data.questions[0];
			}).catch(error => {
				console.log(error);
			});
		});
	},
	methods: {
		selectionChanged: function(selectedAnswer) {
			this.selectedAnswer = selectedAnswer;
		},
		submitAnswer: function() {
			wsClient.defaultClient.quizzes.submitAnswer(this.sessionId, this.quizQuestion.id,
														this.selectedAnswer.id, null)
			.then(res => {
				// correct/incorrect indicator
				this.quizQuestion.question.answers.find(x => x.id === this.selectedAnswer.id).isSelected = false;

				if (res.data.answeredCorrectly) {
					this.quizQuestion.question.answers.find(x => x.id === this.selectedAnswer.id).isCorrect = true;
				}
				else {
					this.quizQuestion.question.answers.find(x => x.id === this.selectedAnswer.id).isIncorrect = true;
				}

				if (res.data.answeredAll) {
					// all done, show result screen
					this.$router.push({ name: 'QuizResults', params: { module: this.module, sessionId: this.sessionId } });
				}
				else {
					this.goToNext = true;
					this.nextQuestion = res.data.nextQuestion;
				}
				this.$refs.questionDisplay.setLock(true);
				this.$refs.questionDisplay.refresh();
			}).catch(error => {
				console.log(error);
			});
		},
		continueToNext: function() {
			this.$refs.questionDisplay.setLock(false);
			this.goToNext = false;
			this.quizQuestion = this.nextQuestion;
			this.selectedAnswer = null;
			window.scrollTo(0, 0);
		}
	},
	computed: {
		module() {
			return this.$route.params.module;
		},
		contentLoad() {
			return import('@/components/modules/' + this.module + '/content.js');
		}
	}
}
</script>

<style lang="postcss" scoped>
	/* Quiz Button */
	.quiz-button {
		@apply w-full bg-solid-green text-white text-2xl px-4 py-6;
		transition: all 0.3s;
		&:hover {
			--bg-opacity: 0.80;
		}
	}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  loggedIn: localStorage.getItem(process.env.VUE_APP_TOKEN_STORAGE_NAME) != null
  },
  mutations: {
	  setLoggedIn(state) {
		  state.loggedIn = true;
	  },
	  setLoggedOut(state) {
		  state.loggedIn = false;
	  }
  },
  actions: {
  },
  modules: {
  }
})

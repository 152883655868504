<template>
	<div class="module-card-basic" :class="cssVariables">
		<section class="card-header-wrapper">
			<slot name="card-header"></slot>
		</section>
		<section class="card-content-wrapper">
			<slot name="card-content"></slot>
		</section>
	</div>
</template>
<script>
export default {
	name: 'ModuleCardBasic',
	props: {
		layoutStyle: {
			type: String
		}
	},
	computed: {
		cssVariables() {
			return this.layoutStyle || 'default'
		}
	}
}
</script>
<style lang="postcss" scoped>
	/* General styles */
	.module-card-basic {
		@apply w-full;
		display: flex;
		flex-direction: column;
		color: var(--card-text-color);
		background-color: var(--card-background-color);
		h3 {
			@apply text-xl font-bold;
			color: var(--card-header-text-color);
		}
	}

	.card-header-wrapper {
		@apply p-4 pb-0;
	}

	.card-content-wrapper {
		@apply px-4 pt-2 pb-4;
	}

	/* Media Queries */
	@screen md {}

	@screen lg {}

	/* Themes */
	.module-card-basic.default {
		--card-text-color: theme('colors.cool-gray');
		--card-background-color: theme('colors.gray.400');
	}

	.module-card-basic.light {
		--card-text-color: #fff;
		--card-background-color: theme('colors.light-teal');
	}

	.module-card-basic.bright {
		--card-text-color: #fff;
		--card-background-color: theme('colors.solid-green');
	}

	.module-card-basic.dark {
		--card-text-color: #fff;
		--card-header-text-color: #fff;
		--card-background-color: theme('colors.dark-teal');
	}

	.module-card-basic.burnt {
		--card-text-color:#fff;
		--card-background-color: theme('colors.burnt-magenta');
	}
</style>

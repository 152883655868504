import Resource from './resource.js';

export default class Quizzes extends Resource {
	async getQuiz(quizId) {
        const response = this.httpGet(`quizzes/${quizId}`, true);
		return response;
	}

	async startQuiz(quizId) {
        const response = this.httpPost(`quizzes/${quizId}/sessions`, null, true);
		return response;
	}

	async submitAnswer(sessionId, quizQuestionId, answerId, answerText) {
		const data = {
			quizQuestionId: quizQuestionId,
			answerId: answerId,
			answerText: answerText
		};
        const response = this.httpPut(`quizzes/sessions/${sessionId}`, data, true);
		return response;
	}

	async submitAnswers(sessionId, answers) {
        const response = this.httpPut(`quizzes/sessions/${sessionId}/answers`, answers, true);
		return response;
	}

	async nextQuestion(sessionId) {
        const response = this.httpGet(`quizzes/sessions/${sessionId}`, true);
		return response;
	}

	async sessionResults(sessionId) {
        const response = this.httpGet(`quizzes/sessions/${sessionId}/results`, true);
		return response;
	}

	async getSessions() {
        const response = this.httpGet('quizzes/sessions', true);
		return response;
	}

	async getSessionStates() {
        const response = this.httpGet('quizzes/sessions/states', true);
		return response;
	}
}

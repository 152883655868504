<template>
    <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2">
        <slot name="left-column-content"></slot>
        <slot name="right-column-content"></slot>
	</div>
</template>

<script>
export default {
    name: 'ContentTwoColumnGeneric'
}
</script>

<style lang="postcss" scoped>
    /* .col-left > *:not(:last-child), .col-right > *:not(:last-child)  {
        @apply mb-4;
    } */
</style>
<template>
	<faculty-card :readMore="readMore" :coiUrl="coiUrl" member="zcooper">
		<template #faculty-title>
			ZIVA COOPER, PhD
		</template>
		<template #faculty-details>
			<p>Director, Cannabis Research Initiative, Jane and Terry Semel Institute</p>
			<p>Associate Professor of Psychiatry, University of California – Los Angeles (UCLA)</p>
		</template>
	</faculty-card>
</template>

<script>
import FacultyCard from './FacultyCard.vue';

export default {
	name: 'ZCooperCard',
	props: ['readMore', 'coiUrl'],
	components: {
		'faculty-card': FacultyCard
	}
}
</script>

<style lang="postcss" scoped>
</style>

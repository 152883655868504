<template>
	<article class="faculty-card space-y-3">
		<div class="faculty-photo-wrapper">
			<img :src="require(`@/assets/images/faculty/${member}.png`)" :alt="member" />
		</div>
		<div class="faculty-title-wrapper">
			<h3 class="text-2xl font-thin">
				<slot name="faculty-title"></slot>
			</h3>
		</div>
		<div class="faculty-details-wrapper space-y-2">
			<slot name="faculty-details"></slot>
		</div>
		<div v-if="readMore" class="faculty-link-wrapper space-y-2">
			<a target="_blank" :href="coiFullUrl">Conflict of interest</a>
			<router-link :to="{ name: 'FacultyMember', params: { member: member } }">Read more</router-link>
		</div>
		<div v-else class="faculty-link-wrapper">
			<a @click="$router.back()">Back</a>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'FacultyCard',
		props: {
			member: {
				type: String
			},
			coiUrl: String,
			readMore: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			coiFullUrl() {
				return `${process.env.VUE_APP_STATIC_BASE_URL}/coi-forms/${this.coiUrl}`;
			}
		}
	}
</script>

<style lang="postcss" scoped>
	.faculty-card {
		display: flex;
		flex-direction: column;
	}
	.faculty-photo-wrapper {
		@apply mb-4;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.faculty-details-wrapper {
		flex-grow: 1;
	}
	.faculty-link-wrapper {
		@apply text-dark-teal font-bold;
		display: flex;
		flex-direction: column;
		> * {
			cursor: pointer;
		}
	}
</style>

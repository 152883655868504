<template>
	<article v-if="quiz != null" class="my-4">
		<section v-if="sessionId != null">
			<p>Thank you for your feedback.</p>
		</section>
		<section v-else class="space-y-4">
			<div>
				<p>The module provided me with the content required to meet these learning objectives:</p>
				<table class="w-full table-auto text-center">
					<thead>
						<tr>
							<th></th>
							<th>Strongly Disagree</th>
							<th>Disagree</th>
							<th>Neutral</th>
							<th>Agree</th>
							<th>Strongly Agree</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(qq, qIdx) in quiz.questions" :key="qIdx" class="">
							<td class="font-bold">{{ qq.question.questionText }}</td>
							<td v-for="(ans, aIdx) in qq.question.answers" :key="aIdx">
								<input :value="ans.id" :name="qq.id" v-model="answers[qq.id]" type="radio" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="flex justify-center">
				<button @click.prevent="submitSurvey" class="w-full md:max-w-xl p-4 bg-solid-green text-white">Submit</button>
			</div>
			<div v-if="surveyError">
				<p class="text-burnt-magenta font-bold">Please complete all survey questions</p>
			</div>
		</section>
	</article>
</template>

<script>
import { defaultClient } from "../ensemble-js/client.js";

export default {
	name: 'SatisfactionSurvey',
	props: {
		quizId: Number
	},
	data() {
		return {
			answers: {},
			quiz: null,
			surveyError: false,
			sessionId: null
		}
	},
	mounted() {
		if (this.quizId !== null) {
			defaultClient.quizzes.getQuiz(this.quizId).then(r => {
				this.quiz = r.data;
				console.log(this.quiz);
			});
		}
	},
	methods: {
		submitSurvey() {
			const answers = [];
			this.quiz.questions.forEach(q => {
				const answerId = this.answers[q.id];
				if (answerId != null) {
					answers.push({
						quizQuestionId: q.id,
						answerId: this.answers[q.id],
						answerText: null
					});
				}
			});
			this.surveyError = this.quiz.questions.length !== answers.length;
			if (!this.surveyError) {
				// create quiz session for feedback survey
				defaultClient.quizzes.startQuiz(this.quizId).then(session => {
					this.sessionId = session.data.sessionId;

					// submit feedback answers to session
					defaultClient.quizzes.submitAnswers(this.sessionId, answers);
					});
				}
			}
		}
	}
</script>
<style lang="postcss" scoped>
</style>

<template>
	<div id="app" class="flex flex-col min-h-screen relative">
		<main-nav />
		<router-view class="flex-grow"/>
		<main-footer />
		<overlay v-if="showOverlay" />
  	</div>
</template>

<script>
import MainNav from '@/components/MainNavigation.vue'
import MainFooter from '@/components/MainFooter.vue'
import Overlay from '@/components/Overlay.vue'

import { EventBus } from './ensemble-js/event-bus.js';

export default {
	name: 'App',
	components: {
		'main-nav': MainNav,
		'main-footer': MainFooter,
		'overlay': Overlay
	},
	data() {
		return {
			showOverlay: false
		}
	},
	mounted() {
		EventBus.$on('showSideNav', () => {
			this.showOverlay = true;
		})
		EventBus.$on('toggleOverlay', () => {
			this.showOverlay = false;
		})
	}
}
</script>

<style lang="postcss">
/* Force scrollbar to always appear */
html {
	overflow-y: scroll;
	font-size: 16px;
	line-height: 1.5rem;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

/* Global App CSS */
#app {
  @apply font-sans-serif;
  color: theme('colors.cool-gray');
  .router-link-exact-active {
	  color: theme('colors.burnt-magenta');
	  font-weight: bold;
  }
}
</style>
